import { createSelector } from "@reduxjs/toolkit";
import { ArticleAddedToShoppingCartRule } from "../../../automation/Rule.ts";
import { selectArticleAddedToShoppingCartRules } from "./selectArticleAddedToShoppingCartRules.ts";

export const selectArticleTriggers = createSelector([selectArticleAddedToShoppingCartRules], (rules) => {
  const articleTriggerIds: Record<string, ArticleAddedToShoppingCartRule[]> = {};

  rules.forEach((rule) => {
    rule.trigger.articleIds?.forEach((articleId) => {
      if (articleTriggerIds[articleId] == null) {
        articleTriggerIds[articleId] = [];
      }
      articleTriggerIds[articleId].push(rule);
    });
  });

  return articleTriggerIds;
});
