import { AnyAction, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { Salesarea } from "../../../types/shared/Salesarea";
import isQr from "../helpers/isQr";
import { setSalesarea } from "./globalSlice";
import { RootState } from "./store";
import { kioskResetted } from "./kioskSlice.tsx";

let userConfirmedAgeBeingAtLeast = undefined;
if (window.hasOwnProperty("sessionStorage")) {
  let value = window.sessionStorage.getItem("V5.userInput.userConfirmedAgeBeingAtLeast");
  if (value) {
    userConfirmedAgeBeingAtLeast = Number.parseInt(value, 10);
    if (Number.isNaN(userConfirmedAgeBeingAtLeast)) {
      userConfirmedAgeBeingAtLeast = undefined;
    }
  }
}

const initialState: {
  // stored as date-fns/unixtimestamp
  userConfirmedAgeBeingAtLeast?: number;
  hasAcceptedTermsAndAgreements: boolean;
  wantsReceipt: boolean;
  flightDetails: object;
  ageCheckEmployeePinCode?: string;
} = {
  userConfirmedAgeBeingAtLeast,
  hasAcceptedTermsAndAgreements: false,
  wantsReceipt: true,
  flightDetails: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    ageConfirmationReceived: (state, { payload: productRequiredAge }: PayloadAction<number>) => {
      state.userConfirmedAgeBeingAtLeast = productRequiredAge;
      if (state.userConfirmedAgeBeingAtLeast === undefined) {
        window.sessionStorage.removeItem("V5.userInput.birthDate");
      } else {
        window.sessionStorage.setItem("V5.userInput.birthDate", state.userConfirmedAgeBeingAtLeast.toString());
      }
    },
    changedTermsAndConditionsAgreement: (
      state,
      { payload: { accepted, salesareaId } }: PayloadAction<{ accepted: boolean; salesareaId: Salesarea["id"] }>
    ) => {
      state.hasAcceptedTermsAndAgreements = accepted;
      if (isQr()) {
        localStorage.setItem(`V5.terms_and_conditions.${salesareaId}`, JSON.stringify(accepted));
      }
    },
    madeDecisionAboutReceipt: (state, { payload: wantsReceipt }: PayloadAction<boolean>) => {
      state.wantsReceipt = wantsReceipt;
    },
    receiptDecisionClearer: (state) => {
      state.wantsReceipt = true;
    },
    flightDetailsSupplied: (state, action) => {
      state.flightDetails = action.payload;
    },
    employeeConfirmedCustomerIsOfAge: (state, { payload: employeePincode }: PayloadAction<string>) => {
      state.ageCheckEmployeePinCode = employeePincode;
    },
    resetUserInputKiosk: (state) => {
      delete state.ageCheckEmployeePinCode;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(setSalesarea, (state, { payload: { id: salesareaId } }) => {
        const key = `V5.terms_and_conditions.${salesareaId}`;
        if (isQr()) {
          const item = localStorage.getItem(key);
          if (item !== null) {
            state.hasAcceptedTermsAndAgreements = JSON.parse(item);
          }
        }
      })
      .addCase(kioskResetted, (state) => {
        state.userConfirmedAgeBeingAtLeast = undefined;
        window.sessionStorage.removeItem("V5.userInput.birthDate");
      }),
});

export const {
  ageConfirmationReceived,
  madeDecisionAboutReceipt,
  flightDetailsSupplied,
  employeeConfirmedCustomerIsOfAge,
  resetUserInputKiosk,
} = userSlice.actions;

export const changedTermsAndConditionsAgreement =
  (accepted: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const salesareaId = getState().global.salesarea?.id as number;
    dispatch(userSlice.actions.changedTermsAndConditionsAgreement({ accepted, salesareaId }));
  };

export default userSlice.reducer;
