import CurrencyRate from "../../models/currencies/CurrencyRate";
import { currencies } from "../../models/currencies/Currency";
import { useCallback } from "react";
import { setCurrencyRates } from "../../redux/menuSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import _ from "lodash";

export default function useParseCurrencyRates() {
  const dispatch = useAppDispatch();
  const currencyRates = useAppSelector((state) => state.menu.currencyRates);

  return useCallback(
    (apiCurrencyRates: any) => {
      const mappedCurrencyRates: Record<string, CurrencyRate> = {};
      if (apiCurrencyRates) {
        apiCurrencyRates = JSON.parse(apiCurrencyRates);

        Object.keys(apiCurrencyRates).forEach((currencyKey) => {
          const currency = currencies[currencyKey];
          if (currency) {
            mappedCurrencyRates[currencyKey] = { currency: currency, value: apiCurrencyRates[currencyKey] };
          }
        });
      }
      if (!_.isEqual(mappedCurrencyRates, currencyRates)) {
        dispatch(setCurrencyRates(mappedCurrencyRates));
      }
    },
    [currencyRates, dispatch]
  );
}
