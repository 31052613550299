import { useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useAppSelector } from "../../utils/redux/store.tsx";

export default function ForceDateAndTimeSnackbar() {
  const simulateTime = useAppSelector((state) => state.dev.simulateTime);

  // allow users to change its position by clicking.
  // it covers buttons behind it which become unclickable
  const [positionTop, setPositionTop] = useState(false);

  return simulateTime?.start ? (
    <Snackbar
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
      anchorOrigin={{ vertical: positionTop ? "top" : "bottom", horizontal: "center" }}
      open={Boolean(simulateTime)}
      onClose={() => {}}
      onClick={() => {
        setPositionTop((prev) => !prev);
      }}
    >
      <Alert severity={"info"}>Ordering disabled, date changed to {simulateTime.start}</Alert>
    </Snackbar>
  ) : null;
}
