import OptionGroup, { getPropertyOfOptionGroup } from "../menu/OptionGroup";
import OrderArticle from "./OrderArticle";
import Article from "../menu/Article.ts";

export default interface OrderOptionGroup {
  id: string;
  optionGroup: OptionGroup;
  orderArticles: OrderArticle[];
}

export function countOrderArticles(orderOptionGroup: OrderOptionGroup) {
  return orderOptionGroup.orderArticles.reduce((count, orderArticle) => {
    count += orderArticle.count;
    return count;
  }, 0);
}

export function countUniqueOrderArticlesInOrderOptionGroups(orderOptionGroups: OrderOptionGroup[]) {
  return orderOptionGroups.reduce((count, orderOptionGroup) => {
    count += orderOptionGroup.orderArticles.reduce((count, orderArticle) => {
      if (orderArticle.count > 0) {
        count += 1;
      }

      return count;
    }, 0);
    return count;
  }, 0);
}

export function countOrderArticlesInOrderOptionGroups(orderOptionGroups: OrderOptionGroup[]) {
  return orderOptionGroups.reduce((count, orderOptionGroup) => {
    count += countOrderArticles(orderOptionGroup);
    return count;
  }, 0);
}

export function isOrderOptionGroupValid(orderOptionGroup: OrderOptionGroup, article: Article) {
  const optionGroup = orderOptionGroup.optionGroup;

  if (optionGroup.optionIds.length === 0) {
    return true;
  }

  const currentCount = countOrderArticles(orderOptionGroup);

  if (currentCount < getPropertyOfOptionGroup(optionGroup, article, "minCount")) {
    return false;
  } else if (
    currentCount > getPropertyOfOptionGroup(optionGroup, article, "maxCount") &&
    getPropertyOfOptionGroup(optionGroup, article, "maxCount") > 0
  ) {
    return false;
  }
  return true;
}

export function isMoreValid(orderOptionGroup: OrderOptionGroup, article: Article, addCount = 0) {
  const optionGroup = orderOptionGroup.optionGroup;
  const currentCount = countOrderArticles(orderOptionGroup);

  // if (currentCount <= optionGroup.minCount) {
  //   if (optionGroup.maxCount > 0) {
  //     return addCount > 0 && addCount + currentCount <= optionGroup.maxCount;
  //   } else {
  //     return addCount > 0;
  //   }
  // } else
  if (
    currentCount >= getPropertyOfOptionGroup(optionGroup, article, "maxCount") &&
    getPropertyOfOptionGroup(optionGroup, article, "maxCount") >=
      getPropertyOfOptionGroup(optionGroup, article, "minCount") &&
    getPropertyOfOptionGroup(optionGroup, article, "maxCount") > 0
  ) {
    return addCount < 0;
  }
  return true;
}
