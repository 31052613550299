import { rtkQueryApi } from "./rtkQueryApi";
import OrderArticle from "../../models/order/OrderArticle";
import { JamezzApiResponse } from "../../../../types/shared/api/common_types";

const sharedShoppingCartEndpoints = rtkQueryApi.enhanceEndpoints({
  addTagTypes: ["sharedShoppingCart-get", "sharedShoppingCart-update", "shoppingCart-get"],
});

export type Transaction = {
  uuid: string;
  orderArticle: OrderArticle;
};

export type ShoppingCartConfirmation = {
  id: string;
  uuid: string;
  response: "APPROVED" | "DENIED" | "NO_RESPONSE_YET";
  response_text: string;
};

export type ShoppingCartConfirmationResponse = {
  data: ShoppingCartConfirmation;
} & JamezzApiResponse;

export const shoppingCartApi = sharedShoppingCartEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    fetchSharedShoppingCart: builder.query<void, string>({
      query: (webcode: string) => {
        return {
          url: `/v5_2/shared-shopping-cart/${webcode}`,
          method: "GET",
        };
      },
      providesTags: (result, _, webcode) => {
        return [
          {
            type: "sharedShoppingCart-get",
            id: webcode,
          },
        ];
      },
    }),
    addTransactionsToSharedShoppingCart: builder.query<void, { transactions: Transaction[]; webcode: string }>({
      query: (data) => {
        return {
          url: `/v5_2/shared-shopping-cart/${data.webcode}/add-transactions`,
          method: "POST",
          body: { transactions: data.transactions },
        };
      },
    }),
    cancelReadyToOrderSharedShoppingCart: builder.mutation<void, string>({
      query: (webcode: string) => {
        return {
          url: `/v5_2/shared-shopping-cart/${webcode}/cancel-ready-to-order`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, webcode) => [{ type: "sharedShoppingCart-get", id: webcode }],
    }),
    requestShoppingCartConfirmation: builder.mutation<
      ShoppingCartConfirmationResponse,
      { shoppingCartUuid: string; requestType: string }
    >({
      query: ({ shoppingCartUuid, requestType }) => {
        return {
          url: `/v5_2/shopping-cart/${shoppingCartUuid}/request-confirmation`,
          method: "POST",
          body: {
            request_type: requestType,
          },
        };
      },
    }),
    updateRequestShoppingCartConfirmation: builder.mutation<
      ShoppingCartConfirmationResponse,
      { shoppingCartUuid: string; response: "APPROVED" | "DENIED"; responseText: string }
    >({
      query: ({ shoppingCartUuid, response, responseText }) => {
        return {
          url: `/v5_2/shopping-cart/${shoppingCartUuid}/request-confirmation`,
          method: "PUT",
          body: {
            response: response,
            response_text: responseText,
          },
        };
      },
    }),
    getShoppingCartConfirmation: builder.query<ShoppingCartConfirmationResponse, string>({
      query: (shoppingCartUuid: string) => {
        return {
          url: `/v5_2/shopping-cart/${shoppingCartUuid}/request-confirmation`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useCancelReadyToOrderSharedShoppingCartMutation,
  useLazyGetShoppingCartConfirmationQuery,
  useGetShoppingCartConfirmationQuery,
  useRequestShoppingCartConfirmationMutation,
  useUpdateRequestShoppingCartConfirmationMutation,
} = shoppingCartApi;
