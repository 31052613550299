import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum BlinkstickStatus {
  Default,
  EancodeError,
  PrinterError,
  Ordered,
  AgeCheckNotification,
}

interface BlinkstickState {
  status: BlinkstickStatus;
}

const initState: BlinkstickState = {
  status: BlinkstickStatus.Default,
};

export const slice = createSlice({
  name: "blinkstick",
  initialState: initState,
  reducers: {
    setBlinkstickStatus: (state, action: PayloadAction<BlinkstickStatus>) => {
      state.status = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBlinkstickStatus } = slice.actions;

export default slice.reducer;
