import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import ArticleTrait, { ArticleTraitType } from "../../models/ArticleTraits/ArticleTrait";
import ArticleTraitValue from "../../models/ArticleTraits/ArticleTraitValue";

export const selectArticleTraitsMap = createSelector(
  [(state: RootState) => state.menuData.products, (state: RootState) => state.menuData.article_traits],
  (
    products,
    article_traits
  ): {
    articleTraitsMap: Record<string, ArticleTrait>;
    quickFilterOnArticleTrait: ArticleTrait | null;
    allArticleTraits: ArticleTrait[];
    traitChildrensMap: Record<string, string>;
  } => {
    const articleTraitsMap: Record<string, ArticleTrait> = {};
    let quickFilterOnArticleTrait: ArticleTrait | null = null;
    const traitChildrensMap: Record<string, string> = {};
    const allArticleTraits: ArticleTrait[] = [];

    const articleTraitValuesUsed: Record<string, Record<string, boolean>> = {};

    products.forEach((apiArticle) => {
      if (articleTraitValuesUsed) {
        const apiArticleTraits = (apiArticle.custom_data ? JSON.parse(apiArticle.custom_data).articleTraits : {}) ?? {};
        Object.keys(apiArticleTraits).forEach((traitKey) => {
          apiArticleTraits[traitKey].values?.forEach((traitValue: string) => {
            if (!articleTraitValuesUsed[traitKey]) {
              articleTraitValuesUsed[traitKey] = {};
            }
            articleTraitValuesUsed[traitKey][traitValue] = true;
          });
        });
      }
    });

    article_traits.forEach((apiArticleTrait) => {
      const apiArticleTraitId = String(apiArticleTrait.apiId);

      if (!articleTraitValuesUsed[apiArticleTrait.apiId]) {
        return;
      }

      const values: { [name: string]: ArticleTraitValue } = {};

      apiArticleTrait.article_trait_values.forEach((articleTraitValue: any) => {
        if (!articleTraitValuesUsed[apiArticleTraitId]?.[articleTraitValue.value]) {
          return;
        }

        // Check for infinite loop
        if (apiArticleTrait.apiId === articleTraitValue.value + ":" + apiArticleTrait.name) {
          return;
        }

        const aTraitValue: ArticleTraitValue = {
          id: String(articleTraitValue.id),
          name: articleTraitValue.value,
          values: [articleTraitValue.value],
          sortKey: articleTraitValue.sort_key,
        };

        values[aTraitValue.name] = aTraitValue;
      });
      if (Object.keys(values).length === 0) {
        return;
      }

      const articleTrait = {
        apiId: apiArticleTraitId,
        name: apiArticleTrait.name,
        values: values,
        type: ArticleTraitType.MULTIPLE_VALUES,
        useAsQuickFilter: apiArticleTrait.use_as_quick_filter,
        updatedAt: "",
      };

      if (apiArticleTrait.use_as_quick_filter) {
        quickFilterOnArticleTrait = articleTrait;
      }

      if (!articleTraitsMap[apiArticleTraitId]) {
        const exploded = articleTrait.apiId.split(":");
        if (exploded.length > 1) {
          traitChildrensMap[exploded[0]] = articleTrait.apiId;
        } else {
          allArticleTraits.push(articleTrait);
        }
      }

      articleTraitsMap[apiArticleTraitId] = articleTrait;
    });

    return { articleTraitsMap, quickFilterOnArticleTrait, allArticleTraits, traitChildrensMap };
  }
);
