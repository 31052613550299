import { createSelector } from "@reduxjs/toolkit";
import Article from "../../models/menu/Article";
import { selectArticlesMap } from "./selectArticlesMap";

export const selectArticlesApiIdMap = createSelector([selectArticlesMap], (articlesMap) => {
  const articlesApiIdMap: Record<string, Article> = {};

  Object.keys(articlesMap).forEach((articleId) => {
    articlesApiIdMap[articlesMap[articleId].apiId1] = articlesMap[articleId];
  });

  return articlesApiIdMap;
});
