import {
  add,
  addDays,
  addMilliseconds,
  addMinutes,
  differenceInSeconds,
  format,
  intervalToDuration,
  isWithinInterval,
  setHours,
  setMinutes,
  setSeconds,
  sub,
} from "date-fns";

import { getTimezoneOffset } from "date-fns-tz";
import { JamezzDay } from "./Time/JamezzDay.ts";
import store from "../redux/store.tsx";

export enum JamezzTimeExceptionTypes {
  OpeningHours = 1,
  PreOrderingHours = 2,
}

export default interface OpeningHours {
  monday: TimeSpan[];
  tuesday: TimeSpan[];
  wednesday: TimeSpan[];
  thursday: TimeSpan[];
  friday: TimeSpan[];
  saturday: TimeSpan[];
  sunday: TimeSpan[];
  exceptions: Record<string, TimeSpan[]>;
}

export interface TimeSpan {
  startTime: MomentOfDay;
  endTime: MomentOfDay;
}

export interface MomentOfDay {
  hours: number;
  minutes: number;
  // seconds: number;
}

export function dayIndexToDay(dayIndex: number): JamezzDay {
  if (dayIndex % 7 === 0) {
    return JamezzDay.sunday;
  } else if (dayIndex % 7 === 1) {
    return JamezzDay.monday;
  } else if (dayIndex % 7 === 2) {
    return JamezzDay.tuesday;
  } else if (dayIndex % 7 === 3) {
    return JamezzDay.wednesday;
  } else if (dayIndex % 7 === 4) {
    return JamezzDay.thursday;
  } else if (dayIndex % 7 === 5) {
    return JamezzDay.friday;
  } else if (dayIndex % 7 === 6) {
    return JamezzDay.saturday;
  }
  return JamezzDay.sunday;
}

export function setDateToJamezzTime(jamezzTime: MomentOfDay, date: Date | null = null) {
  if (!date) {
    date = getNow();
  }

  date = setHours(date, jamezzTime.hours);
  date = setMinutes(date, jamezzTime.minutes);
  date = setSeconds(date, 0);

  return date;
}

export function findOpenStateAndDuration(
  openingHours: OpeningHours,
  date: Date
): { isOpen: boolean; duration: Duration } {
  const dayIndex = date.getDay();
  const day = dayIndexToDay(dayIndex);

  // @ts-ignore
  let timeSpansOfDayFromDate: TimeSpan[] = openingHours[day.valueOf()];
  const dateKey = format(date, "yyyy-MM-dd"); // TODO getNow()
  if (openingHours.exceptions[dateKey]) {
    timeSpansOfDayFromDate = openingHours.exceptions[dateKey];
  }

  const currentTimeSpan = timeSpansOfDayFromDate.find((timeSpan) => {
    const start = setDateToJamezzTime(timeSpan.startTime, date);
    const end = setDateToJamezzTime(timeSpan.endTime, date);

    return isWithinInterval(date, { start, end });
  });

  if (currentTimeSpan) {
    const end = setDateToJamezzTime(currentTimeSpan.endTime, date);

    return { isOpen: true, duration: intervalToDuration({ start: date, end: end }) };
  } else {
    /// Possibly there is a currentTimeSpan on the same date
    for (let index = dayIndex; index < dayIndex + 7; index++) {
      let start = new Date(date.getTime());
      start = addDays(start, index - dayIndex);

      let timeSpans = openingHours[dayIndexToDay(index)];
      const dateKey = format(start, "yyyy-MM-dd");
      if (openingHours.exceptions[dateKey]) {
        timeSpans = openingHours.exceptions[dateKey];
      }

      const differencesPerTimeSpan = timeSpans
        .map((timeSpan) => {
          start = setDateToJamezzTime(timeSpan.startTime, start);

          return { diffInSeconds: differenceInSeconds(start, date), timeSpan };
        })
        .filter((a) => a.diffInSeconds > 0)
        .sort((a, b) => a.diffInSeconds - b.diffInSeconds);

      if (differencesPerTimeSpan.length > 0) {
        let start = new Date(date.getTime());
        start = addDays(start, index - dayIndex);

        start = setDateToJamezzTime(differencesPerTimeSpan[0].timeSpan.startTime, start);

        return { isOpen: false, duration: intervalToDuration({ start: date, end: start }) };
      }
    }

    return { isOpen: false, duration: {} };
  }
}

export function getNow() {
  let date = new Date();
  const simulateTime = store.getState().dev.simulateTime;

  if (simulateTime && simulateTime.direction === "FUTURE") {
    date = add(date, simulateTime.duration);
  } else if (simulateTime && simulateTime.direction === "PAST") {
    date = sub(date, simulateTime.duration);
  }
  // console.log(store.getState().global.salesarea.timezone ?? "Europe/Amsterdam");
  // console.log(getTimezoneOffset(store.getState().global.salesarea.timezone ?? "Europe/Amsterdam") / 1000 / 60);
  // console.log(date.getTimezoneOffset());
  const timezoneCorrection =
    getTimezoneOffset(store.getState().global.salesarea.timezone ?? "Europe/Amsterdam") / 1000 / 60 +
    date.getTimezoneOffset();
  // console.log(timezoneCorrection);
  date = addMinutes(date, timezoneCorrection);
  date = addMilliseconds(date, store.getState().global.clientTimeDifferenceWithServer);
  return date;
}
