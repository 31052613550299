import { createSelector } from "@reduxjs/toolkit";
import EanCode, { EanCodeMapping, OracleEanCode } from "../../models/menu/EanCode";
import { RootState } from "../store";
import md5 from "md5";
import _ from "lodash";
import { ProductResponse } from "../api/menuDataApi";

export const selectEanCodeMapping = createSelector([(state: RootState) => state.menuData.products], (products) => {
  const mapping: EanCodeMapping = { duplicateEans: {}, eanCodes: {} };

  products.forEach((apiArticle) => {
    const eanCodes: { [key: string]: EanCode | OracleEanCode } = parseEansFromApiArticle(apiArticle);

    _.values(eanCodes).forEach((eanCode) => {
      if (mapping.eanCodes[eanCode.id]) {
        if (mapping.eanCodes[eanCode.id].articleId !== eanCode.articleId) {
          // if already exists => make duplicate
          mapping.duplicateEans[eanCode.id] = [mapping.eanCodes[eanCode.id], eanCode];
          delete mapping.eanCodes[eanCode.id];
        }
      } else if (!mapping.duplicateEans[eanCode.id]) {
        // is not duplicate
        mapping.eanCodes[eanCode.id] = eanCode;
      } else {
        mapping.duplicateEans[eanCode.id].push(eanCode);
      }
    });
  });

  return mapping;
});

export function parseEansFromApiArticle(apiArticle: ProductResponse): { [key: string]: EanCode | OracleEanCode } {
  const eanCodes: { [key: string]: EanCode | OracleEanCode } = {};

  const articleId = String(apiArticle.id ?? md5(JSON.stringify(apiArticle)));

  const eansParsed = typeof apiArticle.eans === "string" ? JSON.parse(apiArticle.eans) : apiArticle.eans;
  if (eansParsed) {
    if (Array.isArray(eansParsed)) {
      eansParsed.forEach((ean) => {
        eanCodes[String(ean)] = { id: String(ean), articleId: articleId };
      });
    } else if (typeof eansParsed === "object") {
      for (const eanCode in eansParsed) {
        eanCodes[eanCode] = {
          id: eanCode,
          articleId: articleId,
          price: eansParsed[eanCode],
        };
      }
    }
  }

  if (apiArticle.ean) {
    if (eanCodes[apiArticle.ean] == null) {
      eanCodes[apiArticle.ean] = { id: String(apiArticle.ean), articleId: articleId };
    }
  }
  return eanCodes;
}
