import { createSelector } from "@reduxjs/toolkit";
import store, { RootState } from "../store";
import CustomField from "../../../../types/shared/CustomField";
import { parseCustomFields } from "../../api/parseApis/parseApiCustomFields";
import { customFieldsMasterCode } from "../customFieldsSlice";
import _ from "lodash";

export const selectCustomFields = createSelector(
  [
    (state: RootState) => state.customFields.customFieldsResponse,
    (state: RootState) => state.global.sessionState?.adres ?? "",
    (state: RootState) => state.global.sessionState?.plaats ?? "",
    (state: RootState) => state.global.sessionState?.postcode ?? "",
    (state: RootState) => state.customFields.orderCustomFields,
  ],
  (customFields, adres, plaats, postcode, activeCustomFields): CustomField[] => {
    if (customFields) {
      const parsedCustomFields = parseCustomFields(_.cloneDeep(customFields));

      if (adres && plaats && postcode) {
        const addressCustomFields: CustomField[] = customFieldsMasterCode({ adres, plaats, postcode });

        addressCustomFields.forEach((customField) => {
          const foundCustomField = parsedCustomFields.find((cf) => cf.name === customField.name);
          if (!foundCustomField) {
            parsedCustomFields.push(customField);
          } else {
            Object.assign(foundCustomField, customField);
          }
        });
      }

      // The storeSelector<->path is meant to be expandable once we want to add more "activeRules"
      // at the moment only orderMode is supported, this can be refactored into a super simple mini language once we
      // want to support more rule sources
      return parsedCustomFields.filter((customField) => {
        if (customField.activeRule) {
          const { field, value, operator } = customField.activeRule;
          const [storeSelector, ...path] = field.split(".");

          if (storeSelector === "vatGroup") {
            const orderMode = activeCustomFields?.["OrderMode"];
            if (operator === "=") {
              return value == orderMode?.value;
            } else if (operator === "!=") {
              return value != orderMode?.value;
            } else {
              return false;
            }
          }
          return false;
        }
        return true;
      });
    } else {
      return [];
    }
  }
);

if (window.selectors) {
  window.selectors.selectCustomFields = () => selectCustomFields(store.getState());
}
