import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useEffect, useRef } from "react";

import { v4 as uuidv4 } from "uuid";
import store, { RootState, useAppSelector } from "../../utils/redux/store.tsx";

type KioskTimeoutBlocked = {
  uuids: {
    [uuid: string]: true;
  };
};

const initialState: KioskTimeoutBlocked = { uuids: {} };

const slice = createSlice({
  name: "preventKioskTimeout",
  initialState: initialState,
  reducers: {
    blockingStarted: (state, action: PayloadAction<string>) => {
      state.uuids[action.payload] = true;
    },
    blockingStopped: (state, action: PayloadAction<string>) => {
      delete state.uuids[action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { blockingStarted, blockingStopped } = slice.actions;

export const preventKioskTimeoutReducer = slice.reducer;

export function usePreventKioskTimeout(isBlocking?: boolean) {
  const uuid = useRef<string>("");
  const valueInStore = useAppSelector((state) => state.preventKioskTimeout.uuids[uuid.current]);
  useEffect(() => {
    uuid.current = uuidv4();

    return () => {
      store.dispatch(blockingStopped(uuid.current));
    };
  }, []);

  useEffect(() => {
    if (!valueInStore && isBlocking) {
      store.dispatch(blockingStarted(uuid.current));
    } else if (valueInStore && !isBlocking) {
      store.dispatch(blockingStopped(uuid.current));
    }
  }, [isBlocking, valueInStore]);

  return {
    startLoading: () => {
      store.dispatch(blockingStarted(uuid.current));
    },
    stopLoading: () => {
      store.dispatch(blockingStopped(uuid.current));
    },
  };
}

export function isPreventKioskTimeoutActive() {
  const uuids = store.getState().preventKioskTimeout.uuids;

  return Object.keys(uuids).length > 0;
}

export function usePreventKioskTimeoutActive() {
  const uuids = useAppSelector((state: RootState) => state.preventKioskTimeout.uuids);

  return Object.keys(uuids).length > 0;
}
