import { createSelector } from "@reduxjs/toolkit";
import Campaign, { CampaignPricingRule, XPerGroupForYCampaign } from "../../models/campaigns/Campaign";
import { RootState } from "../store";
import { CampaignResponse, XPerGroupForYCampaignResponse } from "../api/menuDataApi.ts";

export const selectCampaignsMap = createSelector(
  [(state: RootState) => state.menuData.campaigns],
  (campaigns: CampaignResponse[]) => {
    const map: Record<string, Campaign> = {};

    campaigns.forEach((apiCampaign) => {
      let type: CampaignPricingRule | null = null;
      switch (apiCampaign.type) {
        case CampaignPricingRule.NONE:
          type = CampaignPricingRule.NONE;
          break;
        case CampaignPricingRule.WAS_NOW:
          // TODO
          break;
        case CampaignPricingRule.BUY_X_PAY_Y:
          type = CampaignPricingRule.BUY_X_PAY_Y;
          break;
        case CampaignPricingRule.X_FOR_Y:
          type = CampaignPricingRule.X_FOR_Y;
          break;
        case CampaignPricingRule.X_PERCENTAGE_OFF:
          type = CampaignPricingRule.X_PERCENTAGE_OFF;
          break;
        case CampaignPricingRule.XTH_ITEM_Y_PERCENTAGE_OFF:
          type = CampaignPricingRule.XTH_ITEM_Y_PERCENTAGE_OFF;
          break;
        case CampaignPricingRule.X_PER_GROUP_FOR_Y:
          type = CampaignPricingRule.X_PER_GROUP_FOR_Y;
          break;
      }

      if (type) {
        const campaign = {
          id: apiCampaign.id,
          apiId: String(apiCampaign.apiId1),
          type: type,
          name: apiCampaign.name,
          startDateTime: new Date(apiCampaign.start_date_time),
          endDateTime: new Date(apiCampaign.end_date_time),
          mediaUrl: apiCampaign.media_urls.imgid?.[0],
          mediaInCategory: apiCampaign.media_urls.media_in_category?.[0],
          Xs: apiCampaign.custom_data ? JSON.parse(apiCampaign.custom_data) : undefined,
          updatedAt: "",
        };

        if (isXPerGroupForYCampaign(campaign) && isXPerGroupForYCampaignResponse(apiCampaign)) {
          campaign.x_per_group_for_y = apiCampaign.x_per_group_for_y;
        }

        map[apiCampaign.id] = campaign;
      }
    });
    return map;
  }
);

function isXPerGroupForYCampaignResponse(campaign: CampaignResponse): campaign is XPerGroupForYCampaignResponse {
  return campaign.type === "X_PER_GROUP_FOR_Y";
}

function isXPerGroupForYCampaign(campaign: Campaign): campaign is XPerGroupForYCampaign {
  return campaign.type === CampaignPricingRule.X_PER_GROUP_FOR_Y;
}
