import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import OrderArticle, { OrderArticleAnalytics } from "../models/order/OrderArticle";

export interface AiRecordRecommendations {
  product_hash_1: string | null;
  product_hash_2: string | null;
  product_hash_3: string | null;
  product_hash_4: string | null;
}

export interface AiRecord {
  location: number;
  product_hash_1: string | null;
  product_1_in_basket: boolean | false;
  product_hash_2: string | null;
  product_2_in_basket: boolean | false;
  product_hash_3: string | null;
  product_3_in_basket: boolean | false;
  product_hash_4: string | null;
  product_4_in_basket: boolean | false;
  required_products: number | null;
  upsell_displayed: boolean | null;
  recommendations: AiRecordRecommendations | null;
  dlu: string;
}

interface AiState {
  rows: Array<AiRecord>;
  currentUpsell: AiRecord[] | null;
  lastUpsellSource: OrderArticleAnalytics["upsellType"];
}

const initState: AiState = { rows: [], currentUpsell: null, lastUpsellSource: undefined };

function aiProcessItem(
  state: Draft<AiState>,
  action: PayloadAction<{ orderArticle: OrderArticle; lastUpsellSource: OrderArticleAnalytics["upsellType"] }>,
  toggleMode: boolean
) {
  const upsellItems: AiRecord[] = [];
  state.rows.forEach((air: AiRecord) => {
    let currentMatchCount = 0;
    let hasChanges = false;
    if (air.product_hash_1 == action.payload.orderArticle.article.hashedName) {
      air.product_1_in_basket = toggleMode;
      hasChanges = true;
    }
    if (air.product_hash_2 == action.payload.orderArticle.article.hashedName) {
      air.product_2_in_basket = toggleMode;
      hasChanges = true;
    }
    if (air.product_hash_3 == action.payload.orderArticle.article.hashedName) {
      air.product_3_in_basket = toggleMode;
      hasChanges = true;
    }
    if (air.product_hash_4 == action.payload.orderArticle.article.hashedName) {
      air.product_4_in_basket = toggleMode;
      hasChanges = true;
    }
    if (air.product_1_in_basket) currentMatchCount++;
    if (air.product_2_in_basket) currentMatchCount++;
    if (air.product_3_in_basket) currentMatchCount++;
    if (air.product_4_in_basket) currentMatchCount++;

    if (air.required_products == currentMatchCount && hasChanges) {
      if (toggleMode && ((!air.upsell_displayed && toggleMode) || !toggleMode)) {
        upsellItems.push(air);
        air.upsell_displayed = true;
      }
    } else if (air.upsell_displayed && currentMatchCount == 0) {
      air.upsell_displayed = false;
    }
  });
  if (upsellItems.length > 0) {
    state.currentUpsell = upsellItems;
    state.lastUpsellSource = action.payload.lastUpsellSource;
  }
}
export const aiDataSlice = createSlice({
  name: "aiData",
  initialState: initState,
  reducers: {
    setAiRecords: (state: AiState, action: PayloadAction<Array<AiRecord>>) => {
      state.rows = action.payload;
    },
    setBasketProduct: (
      state: AiState,
      action: PayloadAction<{ orderArticle: OrderArticle; lastUpsellSource: OrderArticleAnalytics["upsellType"] }>
    ) => {
      aiProcessItem(state, action, true);
    },
    removeBasketProduct: (
      state: AiState,
      action: PayloadAction<{ orderArticle: OrderArticle; lastUpsellSource: OrderArticleAnalytics["upsellType"] }>
    ) => {
      aiProcessItem(state, action, false);
    },
    clearAiRecords: (state: AiState) => {
      state.rows = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAiRecords, removeBasketProduct, clearAiRecords, setBasketProduct } = aiDataSlice.actions;

export default aiDataSlice.reducer;
