import { createSelector } from "@reduxjs/toolkit";
import { ArticleAddedToShoppingCartRule } from "../../../automation/Rule.ts";
import { selectArticleAddedToShoppingCartRules } from "./selectArticleAddedToShoppingCartRules.ts";

export const selectMenuTriggers = createSelector([selectArticleAddedToShoppingCartRules], (rules) => {
  const menuTriggerIds: Record<string, ArticleAddedToShoppingCartRule[]> = {};

  rules.forEach((rule) => {
    rule.trigger.menuIds?.forEach((menuId) => {
      if (menuTriggerIds[menuId] == null) {
        menuTriggerIds[menuId] = [];
      }
      menuTriggerIds[menuId].push(rule);
    });
  });

  return menuTriggerIds;
});
