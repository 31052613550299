import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { selectArticlegroupsMap } from "./selectArticlegroupsMap";

export const selectTopArticlegroupIds = createSelector([selectArticlegroupsMap], (articlegroupsMap) => {
  const topArticlegroupIds: string[] = [];

  _.values(articlegroupsMap).forEach((articlegroup) => {
    if (!articlegroup.parentId && articlegroup.isVisibleInJamezz) {
      topArticlegroupIds.push(articlegroup.id);
    }
  });

  return topArticlegroupIds.sort((a, b) => articlegroupsMap[a].sortKey - articlegroupsMap[b].sortKey);
});
