import { AnyAction, createSelector, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import CustomField, { CustomFieldType, OrderCustomField } from "../../../types/shared/CustomField";
import store, { RootState } from "./store";
import _ from "lodash";
import isQr from "../helpers/isQr";
import isKiosk from "../helpers/isKiosk";
import { selectCustomFields } from "./selectors/selectCustomFields";

export const orderCustomFields: Record<string, OrderCustomField> = {};

interface CustomFieldsState {
  orderCustomFields: Record<string, OrderCustomField>;
  customFieldsResponse: CustomField[] | null;
  currentCustomField: undefined | string;
}

const initState: CustomFieldsState = {
  orderCustomFields,
  currentCustomField: undefined,
  customFieldsResponse: null,
};

export const shouldRememberCustomField: { [k: string]: boolean } = {
  number: true,
  country: true,
  email: true,
  mobilenr: true,
  text: true,

  date: false,
  ordermode: false,
  hidden: false,
  time: false,
  pagernr: false,
  pager: false,
  paymentmethodbuttons: false,
  paymentmethoddropdown: false,
  select: false,
  selectbuttons: false,
  selectpreorderingtime: false,
  tip: false,
};

function remember(orderCustomFields: CustomFieldsState["orderCustomFields"], salesareaId: number): void {
  if (isQr() && salesareaId > 0) {
    localStorage.setItem(`V5.orderCustomFields.${salesareaId}`, JSON.stringify(orderCustomFields));
  }
}

export const customFieldsSlice = createSlice({
  name: "customFields",
  initialState: initState,
  reducers: {
    setOrderCustomField: (
      state,
      { payload: { orderCustomField } }: PayloadAction<{ orderCustomField: OrderCustomField }>
    ) => {
      state.orderCustomFields[orderCustomField.customFieldName] = orderCustomField;
    },
    clearOrderCustomField: (
      state,
      { payload: { orderCustomField } }: PayloadAction<{ orderCustomField: OrderCustomField }>
    ) => {
      delete state.orderCustomFields[orderCustomField.customFieldName];
    },
    clearOrderCustomFields: (state) => {
      if (isKiosk()) {
        state.orderCustomFields = {};
      } else if (isQr()) {
        console.warn("Do not use clearOrderCustomFields in qr");
      }
    },
    customFieldsResponseRetrieved: (state, action: PayloadAction<string>) => {
      state.customFieldsResponse = JSON.parse(action.payload ?? "[]");
    },
    customFieldCleared: (state, action: PayloadAction<CustomField>) => {
      delete state.orderCustomFields[action.payload.name];
    },
    customFieldNameChanged: (state, { payload: customFieldName }: PayloadAction<string>) => {
      state.currentCustomField = customFieldName;
    },
  },
});

// Action creators are generated for each case reducer function
export const { customFieldsResponseRetrieved, customFieldNameChanged, customFieldCleared } = customFieldsSlice.actions;

export const setOrderCustomField =
  (orderCustomField: OrderCustomField): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const salesareaId = getState().global.salesarea?.id as number;
    // clear field if user emptied it
    if (orderCustomField.value) {
      dispatch(customFieldsSlice.actions.setOrderCustomField({ orderCustomField }));
    } else {
      dispatch(customFieldsSlice.actions.clearOrderCustomField({ orderCustomField }));
    }
    remember(getState().customFields.orderCustomFields, salesareaId);
  };

export function clearFieldsWithoutRemember() {
  const orderCustomFields = store.getState().customFields.orderCustomFields;
  const customFields = selectCustomFields(store.getState());

  _.values(orderCustomFields).forEach((orderCustomField) => {
    const customField = customFields.find((c) => c.name == orderCustomField.customFieldName);
    if (customField && !customField.remember) {
      store.dispatch(customFieldCleared(customField));
    }
  });
}

export const clearOrderCustomFields = (): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch, getState) => {
  const salesareaId = getState().global.salesarea?.id as number;
  dispatch(customFieldsSlice.actions.clearOrderCustomFields());
  remember(getState().customFields.orderCustomFields, salesareaId);
};

export const selectOrderModeCustomField = createSelector([selectCustomFields], (customFields) => {
  return customFields.find((customField) => customField.type === CustomFieldType.OrderMode);
});

export function customFieldsMasterCode(session: { adres: string; postcode: string; plaats: string }): CustomField[] {
  return [
    {
      name: "Adres",
      label: "Adres",
      type: CustomFieldType.Text,
      customfieldType: undefined,
      defaultValue: session.adres,
      disabled: true,
      remember: false,
      required: true,
    },
    {
      name: "Postcode",
      label: "Postcode",
      type: CustomFieldType.Text,
      customfieldType: undefined,
      defaultValue: session.postcode,
      disabled: true,
      remember: false,
      required: true,
    },
    {
      name: "Plaats",
      label: "Plaats",
      type: CustomFieldType.Text,
      customfieldType: undefined,
      defaultValue: session.plaats,
      disabled: true,
      remember: false,
      required: true,
    },
  ];
}

export default customFieldsSlice.reducer;
