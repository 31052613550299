import store, { useAppSelector } from "../../../global/utils/redux/store";
import { useCallback, useEffect } from "react";

// @ts-ignore
import defaultsFile from "../../../assets/langs/defaults.json";
// @ts-ignore
import de from "../../../assets/langs/de.json";
// @ts-ignore
import da from "../../../assets/langs/da.json";
// @ts-ignore
import en from "../../../assets/langs/en.json";
// @ts-ignore
import es from "../../../assets/langs/es.json";
// @ts-ignore
import fr from "../../../assets/langs/fr.json";
// @ts-ignore
import it from "../../../assets/langs/it.json";
// @ts-ignore
import nl from "../../../assets/langs/nl.json";
// @ts-ignore
import sv from "../../../assets/langs/sv.json";
// @ts-ignore
import zh from "../../../assets/langs/zh.json";
// @ts-ignore
import ja from "../../../assets/langs/ja.json";
// @ts-ignore
import hr from "../../../assets/langs/hr.json";
// @ts-ignore
import ca from "../../../assets/langs/ca.json";
// @ts-ignore
import ar from "../../../assets/langs/ar.json";

import { userSelectedLanguage } from "../../../global/utils/redux/globalSlice";
import { setLanguageInSession } from "../../../global/utils/api/useSetLanguageInSession";
import { StringParam, useQueryParam } from "use-query-params";
import { Language } from "../../../types/shared/language";
import { createSelector } from "@reduxjs/toolkit";
import isQr from "../../../global/utils/helpers/isQr.tsx";

function map(object: any) {
  const newObject: any = {};
  Object.keys(object).forEach((key) => {
    if (object[key].OVERWRITE) {
      newObject[key] = object[key].OVERWRITE.translation;
    } else if (object[key].AI?.translation) {
      newObject[key] = object[key].AI.translation;
    }
  });

  return newObject;
}

export const messagesForLanguages: { [lang in Language]: any } = {
  de: map(de),
  da: map(da),
  en: map(en),
  es: map(es),
  fr: map(fr),
  it: map(it),
  nl: map(nl),
  sv: map(sv),
  zh: map(zh),
  ja: map(ja),
  hr: map(hr),
  ca: map(ca),
  ar: map(ar),
};

export const selectAvailableLanguages = createSelector(
  [(state) => state.global.sessionState?.showLangselLanguages, (state) => state.global.salesarea.applicationLanguage],
  (languagesString, applicationLanguage) => {
    const languages = (languagesString ? (JSON.parse(languagesString) as Language[]) : null) ?? [];
    if (!languages.includes(applicationLanguage) && applicationLanguage) {
      languages.push(applicationLanguage);
    }
    return languages;
  }
);

export function languageIsValid(language: Language, languages: Language[]) {
  return languages.includes(language);
}

export const selectAppLanguage = createSelector(
  [
    (state) => state.global.sessionState?.lang,
    (state) => state.global.userSelectedLanguage,
    selectAvailableLanguages,
    (state) => state.global.salesarea.applicationLanguage,
  ],
  (sessionLanguage, selectedLanguage, availableLanguages, applicationLanguage): Language => {
    if (selectedLanguage && languageIsValid(selectedLanguage, availableLanguages)) {
      return selectedLanguage;
    }
    if (sessionLanguage && languageIsValid(sessionLanguage, availableLanguages)) {
      return sessionLanguage as Language;
    }

    return applicationLanguage ?? availableLanguages[0] ?? "en";
  }
);

export function setLanguage(lang: Language) {
  setLanguageInSession(lang);
  store.dispatch(userSelectedLanguage(lang));
}

function useLanguage() {
  const availableLanguages = useAppSelector(selectAvailableLanguages);

  useEffect(() => {
    if (isQr()) {
      const lang: Language = navigator.language.substr(0, 2) as Language;
      if (sessionStorage.getItem("V5.userSelectedLanguage") == null && languageIsValid(lang, availableLanguages)) {
        setLanguage(lang);
      }
    }
  }, [availableLanguages]);

  const [languageQueryParam] = useQueryParam("language", StringParam);
  useEffect(() => {
    if (languageQueryParam) setLanguage(languageQueryParam as Language);
  }, [languageQueryParam]); // do not add setLanguage as dependency
}

export function useResetLanguage() {
  const applicationLanguage = useAppSelector((state) => state.global.salesarea?.applicationLanguage);
  const [languageQueryParam] = useQueryParam("language", StringParam);
  return useCallback(() => {
    if (!languageQueryParam) {
      setLanguageInSession(applicationLanguage);
      store.dispatch(userSelectedLanguage(applicationLanguage));
    }
  }, [applicationLanguage, languageQueryParam]);
}

export const defaultMessages = defaultsFile;

export default useLanguage;
