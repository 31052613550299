import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store.tsx";
import OrderArticle from "../../models/order/OrderArticle.ts";
import { VoucherV2 } from "../vouchersV2Slice.tsx";

function findOrderArticles(vouchers: VoucherV2[]): OrderArticle[] {
  let orderArticles: OrderArticle[] = [];

  for (const voucher of vouchers) {
    if (voucher.orderArticles) {
      orderArticles = orderArticles.concat(voucher.orderArticles);
    }

    if (voucher.subVouchers) {
      orderArticles = orderArticles.concat(findOrderArticles(voucher.subVouchers));
    }
  }
  return orderArticles;
}

export const selectOrderArticlesFromVouchersV2 = createSelector(
  [(state: RootState) => state.vouchersV2.vouchers],
  (vouchers) => {
    return findOrderArticles(vouchers);
  }
);
