import { createSelector } from "@reduxjs/toolkit";
import { selectArticlesMap } from "./selectArticlesMap.ts";
import { RootState } from "../store.tsx";
import { ItemDiscount, simphonyApi } from "../api/simphonyApi.ts";
import OrderArticle from "../../models/order/OrderArticle.ts";
import { initSystemProduct } from "../shoppingCartSlice.tsx";
import { processOrderCheckerResult } from "../../hooks/useOrderChecker.tsx";

export const selectOracleDiscountIds = createSelector(
  [(state: RootState) => state.global.salesarea.mwise.enabled, (state: RootState) => state.mwise.claimedReward],
  (enabled, claimedReward) => (enabled && claimedReward ? [claimedReward.oracleDiscountId] : undefined)
);

export const selectCheckCalculatorDiscounts = createSelector(
  [
    (state: RootState) =>
      simphonyApi.endpoints.checkCalculator.select({ fixedCacheKey: "checkCalculator-SIMPHONY", requestId: undefined })(
        state
      ).data,
    selectArticlesMap,
    (state: RootState) => state.global.salesarea.check_calculate_settings,
  ],
  (response, articlesMap, check_calculate_settings) => {
    const discountOrderArticles: OrderArticle[] = [];

    if (check_calculate_settings.enabled) {
      if (response == null) {
        return undefined;
      }
      const discount_product_id = check_calculate_settings.product_id;

      let discounts: ItemDiscount[] = [...response.data.discounts];

      if (response.data.response) {
        response.data.response.menuItems?.forEach((menuItem) => {
          menuItem.condiments.forEach((condiment) => {
            if (condiment.itemDiscounts) {
              discounts = discounts.concat(
                condiment.itemDiscounts.map((itemDiscount) => ({ ...itemDiscount, total: -itemDiscount.total }))
              );
            }
          });
        });
      } else {
        processOrderCheckerResult(response.data);
      }

      console.log(discounts);

      // this code can look complicated. It's a workaround for a bug in Oracle.
      // It can for examples return the following:
      // {
      //   discounts: [
      //     {id: 123, total: 0},
      //     {id: 123, total: 5},
      //   ]
      // }
      // instead, it -should- have given us this
      // {
      //   discounts: [
      //     {id: 123, total: 0 + 5},
      //   ]
      // }
      // this code assumes that (1) there can be more than one duplicate discount id
      // and (2) the duplicates can span more than 2 duplications
      // so this handles all cases of duplication,
      // the end result will never have duplicate ids, and all totals will be summed per id
      const uniqueIds = new Set(discounts.map((d) => d.discountId));
      // if there are duplicates
      if (uniqueIds.size < discounts.map((d) => d.discountId).length) {
        // rebuild discounts with duplicates summed
        discounts = Array.from(uniqueIds).map((discountId) => ({
          name: discounts.find((d) => d.discountId === discountId)?.name ?? "",
          discountId,
          total: discounts.filter((d) => d.discountId === discountId).reduce((sum, d) => sum + d.total, 0),
        }));
      }

      discounts.forEach((discount, index) => {
        const discount_product = initSystemProduct(articlesMap, discount_product_id);
        discount_product.uuid = "DiscountProductOracle" + index;
        discount_product.article.name = discount.name;
        discount_product.article.hasCustomizedPriceAndName = true;
        discount_product.article.originalPrice = discount.total;
        discount_product.article.price = discount.total;
        discount_product.editable = false;
        if (!discount_product.article.customData) {
          discount_product.article.customData = {};
        }
        discount_product.article.customData.oracleDiscount = discount;
        discount_product.sendToApi = false;

        discountOrderArticles.push(discount_product);
      });
    }

    return discountOrderArticles;
  }
);
