import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DialogContainer } from "./DialogContainer";

import { v4 as uuidv4 } from "uuid";
import { DialogProps } from "@mui/material";
import store from "../redux/store";
import _ from "lodash";

const DialogContext = createContext<{
  openDialog: (option: DialogOption) => string;
  closeDialog: () => void;
  closeAllDialogs: () => void;
  dialogIsOpen: (id: string) => boolean;
  getDialogsCount: () => number;
}>({
  closeAllDialogs(): void {},
  closeDialog(): void {},
  dialogIsOpen(): boolean {
    return false;
  },
  openDialog(): string {
    return "";
  },
  getDialogsCount: () => 0,
});

export const useDialog = () => {
  return useContext(DialogContext);
};

export interface DialogOption extends Omit<DialogProps, "open" | "fullScreen"> {
  disabledBackdropClick?: boolean;
  open?: boolean;
  fullScreen?: boolean | "almostFullScreen";
}

export default function DialogProvider({ children }: { children: ReactNode }) {
  const [dialogs, setDialogs] = useState<DialogOption[]>([]);

  const openDialog = useCallback((option: DialogOption): string => {
    const id = uuidv4();
    const { disabledBackdropClick } = option;
    const dialog: DialogOption = { ...option, open: true, disabledBackdropClick, id };

    let navigateTo = window.location.pathname as string;
    navigateTo = navigateTo.replace(":webcode", store.getState().global.webcode);
    // push history on stack so back-button works
    // @ts-ignore
    window.history.pushState(null, null, navigateTo);
    // navigate(window.location.pathname); // do not use this one, this rerenders entire app
    setDialogs((dialogs) => [...dialogs, dialog]);
    return id;
  }, []);

  const dialogIsOpen = useCallback(
    (id: string) => {
      return dialogs.find((d) => d.id === id) !== undefined;
    },
    [dialogs]
  );

  const closeAllDialogs = useCallback(() => {
    setDialogs(() => {
      return [];
    });
  }, []);

  const closeDialog = useCallback(() => {
    setDialogs((dialogs) => {
      if (dialogs.length > 0) {
        const newDialogs = [...dialogs];

        let latestOpenDialogIndex = -1;
        for (let i = newDialogs.length - 1; i >= 0; i--) {
          if (newDialogs[i].open) {
            latestOpenDialogIndex = i;
            break;
          }
        }

        if (latestOpenDialogIndex < 0) {
          return dialogs;
        }

        newDialogs[latestOpenDialogIndex] = { ...newDialogs[latestOpenDialogIndex] };
        newDialogs[latestOpenDialogIndex].open = false;
        const latestOpenDialogId = newDialogs[latestOpenDialogIndex].id;
        const onClose = newDialogs[latestOpenDialogIndex]?.onClose;
        if (onClose) {
          onClose({}, "escapeKeyDown");
        }
        setTimeout(() => {
          setDialogs((dialogs) => {
            const index = dialogs.findIndex((dialog) => dialog.id === latestOpenDialogId);
            if (index >= 0) {
              dialogs.splice(index, 1);
            }
            return dialogs;
          });
        }, 2000);
        return [...newDialogs];
      }

      return [...dialogs];
    });
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", closeDialog);
    return () => {
      window.removeEventListener("popstate", closeDialog);
    };
  }, []);

  const getDialogsCount = useCallback(() => {
    let count = 0;
    setDialogs((dialogs) => {
      count = dialogs.length;
      return dialogs;
    });
    return count;
  }, []);

  const contextValue = useMemo(() => ({ openDialog, closeDialog, closeAllDialogs, dialogIsOpen, getDialogsCount }), []);

  return (
    <DialogContext.Provider value={contextValue}>
      {children}
      {dialogs.map((dialog) => {
        const { fullScreen, sx, disabledBackdropClick, ...otherProps } = dialog;
        const almostFullScreenSx = { ".MuiDialog-paperFullScreen": { width: "95%", height: "97%", borderRadius: 12 } };

        return (
          <DialogContainer
            key={dialog.id}
            open={true}
            {...otherProps}
            disabledBackdropClick={fullScreen === "almostFullScreen" ? true : disabledBackdropClick}
            fullScreen={Boolean(fullScreen)}
            sx={fullScreen === "almostFullScreen" ? _.merge(almostFullScreenSx, sx) : sx}
          />
        );
      })}
    </DialogContext.Provider>
  );
}
