import OrderArticle from "../models/order/OrderArticle";

export default function parseShoppingCart(items: OrderArticle[]): OrderArticle[] {
  let parsedItems = JSON.parse(JSON.stringify(items));
  parsedItems = parsedItems.filter((orderArticle: OrderArticle) => orderArticle.count > 0);

  // Niet neerzetten hier
  // parsedItems = parsedItems.sort((itemA: OrderArticle, itemB: OrderArticle) => {
  //   return itemA.article.sortKey - itemB.article.sortKey;
  // });

  parsedItems.forEach((orderArticle: OrderArticle) => {
    if (orderArticle.article.price != orderArticle.article.originalPrice) {
      orderArticle.article.hasCustomizedPriceAndName = true;
    }
    orderArticle.orderOptionGroups = orderArticle.orderOptionGroups.filter((orderOptionGroup) => {
      orderOptionGroup.orderArticles = parseShoppingCart(orderOptionGroup.orderArticles);
      return orderOptionGroup.orderArticles.length > 0;
    });
  });

  return parsedItems;
}
