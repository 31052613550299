import { addListener, createListenerMiddleware, TypedAddListener, TypedStartListening } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "./store";
import { simphonyCheckCalculatorListener } from "./listeners/simphonyCheckCalculatorListener";
import { sharedShoppingCartListenerAddItem } from "./listeners/sharedShoppingCartListenerAddItem";
import { refetchTableStateOnOrderListener } from "./listeners/ayceListener";
import { customFields2Listener, customFieldsListener } from "./listeners/customFieldsListener";
import { orderArticlesAddedListener } from "./listeners/orderArticlesAddedListener.ts";

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>;

sharedShoppingCartListenerAddItem();
simphonyCheckCalculatorListener();
customFieldsListener();
customFields2Listener();
refetchTableStateOnOrderListener();
orderArticlesAddedListener();
