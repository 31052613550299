import { Dialog, DialogProps } from "@mui/material";

import { useDialog } from "./DialogProvider";
import { useAppSelector } from "../redux/store";

export function DialogContainer(props: DialogProps & { disabledBackdropClick?: boolean }) {
  const { children, fullWidth, maxWidth, fullScreen, disabledBackdropClick, ...otherProps } = props;
  const { closeDialog } = useDialog();

  const zoomFactor = useAppSelector((state) => state.global.salesarea?.custom_data?.kiosk?.zoom_factor ?? 0);

  return (
    <Dialog
      disableAutoFocus={true} // Do not remove, is needed for iframe usage.
      {...otherProps}
      fullScreen={fullScreen ?? false}
      fullWidth={fullWidth ?? true}
      maxWidth={maxWidth ?? (zoomFactor > 0 ? false : "md")}
      onClose={(event, reason) => {
        if (reason != "backdropClick" || !disabledBackdropClick) {
          closeDialog();
          props.onClose?.(event, reason);
        }
      }}
    >
      {children}
    </Dialog>
  );
}
