import OrderArticle, { getTotalPrice } from "../../models/order/OrderArticle";
import Campaign from "../../models/campaigns/Campaign";

export default function calculateXthItemYPercentageOffCampaign(
  campaign: Campaign,
  orderArticles: OrderArticle[],
  priceLineId: number | null
) {
  let discountAmount = 0;

  if (campaign.Xs?.length === 2) {
    orderArticles = orderArticles.sort(
      (a, b) =>
        getTotalPrice(a, priceLineId, { count: 1, includeOptions: false, includeExtraOrderArticles: false }) -
        getTotalPrice(b, priceLineId, { count: 1, includeOptions: false, includeExtraOrderArticles: false })
    );
    const totalCount = orderArticles.reduce((sum, orderArticle) => {
      return sum + orderArticle.count;
    }, 0);
    const numberOfDiscountedItems = Math.floor(totalCount / campaign.Xs[0]);

    let numberOfItemsDiscounted = 0;
    orderArticles.some((orderArticle) => {
      if (numberOfItemsDiscounted + orderArticle.count <= numberOfDiscountedItems) {
        discountAmount +=
          (getTotalPrice(orderArticle, priceLineId, { includeOptions: false, includeExtraOrderArticles: false }) *
            (campaign.Xs?.[1] ?? 0)) /
          100;
        numberOfItemsDiscounted += orderArticle.count;
        return false;
      } else {
        const count = numberOfDiscountedItems - numberOfItemsDiscounted;
        discountAmount +=
          (getTotalPrice(orderArticle, priceLineId, {
            count,
            includeOptions: false,
            includeExtraOrderArticles: false,
          }) *
            (campaign.Xs?.[1] ?? 0)) /
          100;
        numberOfItemsDiscounted += count;
        return true;
      }
    });
  }
  return discountAmount;
}
