import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { selectArticlegroupsMap } from "./selectArticlegroupsMap";

export const selectArticlegroupChildrenMap = createSelector([selectArticlegroupsMap], (articlegroupsMap) => {
  const map: Record<string, Record<string, boolean>> = {};

  _.values(articlegroupsMap).forEach((articlegroup) => {
    if (articlegroup.parentId) {
      if (!map[articlegroup.parentId]) {
        map[articlegroup.parentId] = {};
      }
      map[articlegroup.parentId][articlegroup.id] = true;
    }

    if (articlegroup.category) {
      if (!map[articlegroup.category]) {
        map[articlegroup.category] = {};
      }
      map[articlegroup.category][articlegroup.id] = true;
    }
  });
  return _.chain(map)
    .mapValues((record) => {
      return _.keys(record).sort((a: string, b: string) => articlegroupsMap[a]?.sortKey - articlegroupsMap[b]?.sortKey);
    })
    .value();
});
