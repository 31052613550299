import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import _ from "lodash";
import { selectArticlesMap } from "./selectArticlesMap";
import { selectArticlegroupsMap } from "./selectArticlegroupsMap";

export const selectArticlegroupArticlesMap = createSelector(
  [(state: RootState) => state.menuData.menukaart_products, selectArticlesMap, selectArticlegroupsMap],
  (menukaartProducts, articlesMap, articlegroupsMap) => {
    const articlegroupArticlesMap: Record<string, Record<string, boolean>> = {};
    menukaartProducts
      .filter((entry) => articlesMap[entry.product_id] && articlegroupsMap[entry.menukaart_id])
      .forEach((menukaartProduct) => {
        if (!articlegroupArticlesMap[menukaartProduct.menukaart_id]) {
          articlegroupArticlesMap[menukaartProduct.menukaart_id] = {};
        }

        articlegroupArticlesMap[menukaartProduct.menukaart_id][menukaartProduct.product_id] = true;
      });

    return _.chain(articlegroupArticlesMap)
      .mapValues((record) => {
        return _.keys(record).sort((a, b) => articlesMap[a]?.sortKey - articlesMap[b]?.sortKey);
      })
      .value();
  }
);
