import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Articlegroup from "../models/menu/Articlegroup";
import Currency from "../models/currencies/Currency";
import {
  ArticleTraitResponse,
  CampaignProductResponse,
  CampaignResponse,
  CategoryItemResponse,
  MenukaartProductResponse,
  OptionGroupResponse,
  ProductResponse,
  TagResponse,
  TimeScheduleResponse,
  UpsellResponse,
} from "./api/menuDataApi";
import store from "./store";
import { selectArticlesMap } from "./selectors/selectArticlesMap";
import { selectDiscountsPerCampaign, selectSumOfDiscountsPerCampaign } from "./selectors/discountsPerCampaign";
import { selectArticleAddedToShoppingCartRules } from "./selectors/upsells/selectArticleAddedToShoppingCartRules.ts";
import { selectArticleArticlegroupsMap } from "./selectors/selectArticleArticlegroupsMap";
import { selectArticleArticlegroupsMapUnfiltered } from "./selectors/selectArticleArticlegroupsMapUnfiltered";
import { selectArticleCampaignsMap } from "./selectors/selectArticleCampaignsMap";
import { selectArticlegroupArticlesMap } from "./selectors/selectArticlegroupArticlesMap";
import { selectArticlegroupChildrenMap } from "./selectors/selectArticlegroupChildrenMap";
import { selectArticlegroupParentsMap } from "./selectors/selectArticlegroupParentsMap";
import { selectArticlegroupsMap } from "./selectors/selectArticlegroupsMap";
import { selectArticleTraitsMap } from "./selectors/selectArticleTraitsMap";
import { selectCampaignsMap } from "./selectors/selectCampaignsMap";
import { selectCustomizableTexts } from "./selectors/selectCustomizableTexts";
import { selectTopArticlegroupIds } from "./selectors/selectTopArticlegroupIds";
import { selectSupergroupsMap } from "./selectors/selectSupergroupsMap";
import { selectServiceRequestArticles } from "./selectors/selectServiceRequestArticles";
import { selectOptionGroupsMap } from "./selectors/selectOptionGroupsMap";
import { selectEanCodeMapping } from "./selectors/selectEanCodeMapping";
import { selectArticlesApiIdMap } from "./selectors/selectArticlesApiIdMap";
import _ from "lodash";
import { selectArticleTriggers } from "./selectors/upsells/selectArticleTriggers.ts";
import { selectMenuTriggers } from "./selectors/upsells/selectMenuTriggers.ts";
import { selectTimeSchedules } from "../../domains/timeSchedules/selectors/selectTimeSchedules.ts";
import { selectActiveArrangement } from "./arrangements/selectActiveArrangement.tsx";

interface MenuDataState {
  menuLoaded: boolean;
  productsLoaded: boolean;
  menu_updated_at: string | null;
  menukaarts: Articlegroup[];
  menukaart_products: MenukaartProductResponse[];
  category_items: CategoryItemResponse[];
  option_groups: OptionGroupResponse[];
  products: ProductResponse[];
  tags: TagResponse[];
  article_traits: ArticleTraitResponse[];
  campaigns: CampaignResponse[];
  campaign_products: CampaignProductResponse[];
  upsells: UpsellResponse[];
  time_schedules: TimeScheduleResponse[];
}

export const defaultCurrency: Currency = { id: "EUR", name: "EURO", symbol: "€" };

const initState: MenuDataState = {
  menuLoaded: false,
  productsLoaded: false,
  menukaarts: [],
  menukaart_products: [],
  category_items: [],
  option_groups: [],
  products: [],
  tags: [],
  article_traits: [],
  campaigns: [],
  campaign_products: [],
  upsells: [],
  time_schedules: [],
  menu_updated_at: null,
};

export const menuDataSlice = createSlice({
  name: "menu",
  initialState: initState,
  reducers: {
    setMenuDataArticlegroups: (state, action: PayloadAction<Articlegroup[]>) => {
      state.menuLoaded = true;
      state.menukaarts = action.payload;
    },
    setMenuDataMenukaartProducts: (state, action: PayloadAction<MenukaartProductResponse[]>) => {
      state.menukaart_products = action.payload;
    },
    setMenuDataCategoryItems: (state, action: PayloadAction<CategoryItemResponse[]>) => {
      state.category_items = action.payload;
    },
    setMenuDataOptionGroups: (state, action: PayloadAction<OptionGroupResponse[]>) => {
      state.option_groups = action.payload;
    },
    setMenuDataProducts: (state, action: PayloadAction<ProductResponse[]>) => {
      state.products = action.payload;
      state.productsLoaded = true;
    },
    setMenuDataProductsPreloaded: (state, action: PayloadAction<ProductResponse[]>) => {
      state.products = action.payload;
    },
    setMenuDataTags: (state, action: PayloadAction<TagResponse[]>) => {
      state.tags = action.payload;
    },
    setMenuDataArticleTraits: (state, action: PayloadAction<ArticleTraitResponse[]>) => {
      state.article_traits = action.payload;
    },
    setMenuDataCampaigns: (state, action: PayloadAction<CampaignResponse[]>) => {
      state.campaigns = action.payload;
    },
    setMenuDataCampaignProducts: (state, action: PayloadAction<CampaignProductResponse[]>) => {
      state.campaign_products = action.payload;
    },
    upsellsReceived: (state, action: PayloadAction<UpsellResponse[]>) => {
      state.upsells = action.payload;
    },
    timeSchedulesReceived: (state, action: PayloadAction<TimeScheduleResponse[]>) => {
      state.time_schedules = action.payload;
    },
    menuUpdatedAt: (state, action: PayloadAction<string | null>) => {
      state.menu_updated_at = action.payload;
    },
    stockUpdated: (state, action: PayloadAction<{ articleId: string; newStock: number }[]>) => {
      const articleIds = _.chain(action.payload).keyBy("articleId").value();
      state.products.forEach((product) => {
        if (articleIds[product.id]) {
          product.stock = articleIds[product.id].newStock;
        }
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMenuDataArticlegroups,
  setMenuDataMenukaartProducts,
  setMenuDataCategoryItems,
  setMenuDataOptionGroups,
  setMenuDataProducts,
  setMenuDataProductsPreloaded,
  setMenuDataTags,
  setMenuDataArticleTraits,
  setMenuDataCampaigns,
  setMenuDataCampaignProducts,
  menuUpdatedAt,
  stockUpdated,
  upsellsReceived,
  timeSchedulesReceived,
} = menuDataSlice.actions;

export default menuDataSlice.reducer;

const selectorsApi = {
  selectArticlesMap: () => selectArticlesMap(store.getState()),
  selectArticlesApiIdMap: () => selectArticlesApiIdMap(store.getState()),
  selectDiscountsPerCampaign: () => selectDiscountsPerCampaign(store.getState()),
  selectSumOfDiscountsPerCampaign: () => selectSumOfDiscountsPerCampaign(store.getState()),
  selectArticleAddedToShoppingCartRules: () => selectArticleAddedToShoppingCartRules(store.getState()),
  selectArticleArticlegroupsMap: () => selectArticleArticlegroupsMap(store.getState()),
  selectArticleArticlegroupsMapUnfiltered: () => selectArticleArticlegroupsMapUnfiltered(store.getState()),
  selectArticleCampaignsMap: () => selectArticleCampaignsMap(store.getState()),
  selectArticlegroupArticlesMap: () => selectArticlegroupArticlesMap(store.getState()),
  selectArticlegroupChildrenMap: () => selectArticlegroupChildrenMap(store.getState()),
  selectArticlegroupParentsMap: () => selectArticlegroupParentsMap(store.getState()),
  selectArticlegroupsMap: () => selectArticlegroupsMap(store.getState()),
  selectArticleTraitsMap: () => selectArticleTraitsMap(store.getState()),
  selectCampaignsMap: () => selectCampaignsMap(store.getState()),
  selectCustomizableTexts: () => selectCustomizableTexts(store.getState()),
  selectEanCodeMapping: () => selectEanCodeMapping(store.getState()),
  selectOptionGroupsMap: () => selectOptionGroupsMap(store.getState()),
  selectServiceRequestArticles: () => selectServiceRequestArticles(store.getState()),
  selectSupergroupsMap: () => selectSupergroupsMap(store.getState()),
  selectTopArticlegroupIds: () => selectTopArticlegroupIds(store.getState()),
  selectArticleTriggers: () => selectArticleTriggers(store.getState()),
  selectMenuTriggers: () => selectMenuTriggers(store.getState()),
  selectTimeSchedules: () => selectTimeSchedules(store.getState()),
  selectActiveArrangement: () => selectActiveArrangement(store.getState()),
};

if (window.selectors) {
  Object.assign(window.selectors, selectorsApi);
} else {
  window.selectors = selectorsApi;
}
