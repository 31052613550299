import { createSelector } from "@reduxjs/toolkit";
import { arrangementsApi } from "./arrangementsApi.ts";
import _ from "lodash";

export const selectTableState = createSelector(
  [
    (state) => arrangementsApi.endpoints.fetchTableState.select(state.arrangements.ticket_pincode.pincode)(state).data,
    (state) =>
      arrangementsApi.endpoints.fetchTableState.select(state.arrangements.ticket_pincode.pincode)(state).isError,
    (state) => state.dev.arrangementTableItems,
  ],
  (tableState, isError, arrangementTableItems) => {
    if (arrangementTableItems) {
      tableState = _.cloneDeep(tableState);
      if (tableState && "data" in tableState && tableState.data) {
        tableState.data.table_items = arrangementTableItems;
      }
    }

    return { data: tableState, isError };
  }
);
