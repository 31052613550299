import { useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useAppSelector } from "../../utils/redux/store.tsx";

export default function ForceOpenSnackbar() {
  const forcedOpen = useAppSelector((state) => state.dev.forcedOpen);

  // allow users to change its position by clicking.
  // it covers buttons behind it which become unclickable
  const [positionTop, setPositionTop] = useState(false);

  return (
    <Snackbar
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
      anchorOrigin={{ vertical: positionTop ? "top" : "bottom", horizontal: "center" }}
      open={forcedOpen}
      onClose={() => {}}
      onClick={() => {
        setPositionTop((prev) => !prev);
      }}
    >
      <Alert severity={"info"}>Ordering disabled, salesarea state is forced to OPEN!</Alert>
    </Snackbar>
  );
}
