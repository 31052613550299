export type TimeSchedule<T extends Record<string, any>> = T & {
  opening_hours: {
    monday: TimeSpan[];
    tuesday: TimeSpan[];
    wednesday: TimeSpan[];
    thursday: TimeSpan[];
    friday: TimeSpan[];
    saturday: TimeSpan[];
    sunday: TimeSpan[];
  };
  is_active: boolean;
  is_open: boolean;
};

type TimeSpan = string;

export const weekDaysDateFns: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
