import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionTranslations } from "../models/menu/Article";
import { RootState } from "./store";
import _ from "lodash";
import OrderArticle, { initOrderArticle } from "../models/order/OrderArticle";
import isKiosk from "../helpers/isKiosk";
import isQr from "../helpers/isQr";
import { selectArticleArticlegroupsMap } from "./selectors/selectArticleArticlegroupsMap";
import { selectArticlesMap } from "./selectors/selectArticlesMap";

export interface PackagingType {
  id: string;
  name: string;
  order_mode_takeaway_enabled: boolean;
  order_mode_eat_in_enabled: boolean;
  order_mode_delivery_enabled: boolean;
  translations: OptionTranslations;
}

export interface Packaging {
  id: string;
  name: string;

  translations: OptionTranslations;

  productIds: Record<string, boolean>;
  menukaartIds: Record<string, boolean>;

  add_price_per_item: boolean;
  add_price_per_order: boolean;
  packaging_type_id: string | undefined;
  product_id: string | undefined;
}

interface PackagingsState {
  packagings: Record<string, Packaging>;
  packagingTypes: Record<string, PackagingType>;
  userSelectedPackagingTypeId: undefined | string;
}

const initState: PackagingsState = {
  packagings: {},
  packagingTypes: {},
  userSelectedPackagingTypeId: undefined,
};

export const slice = createSlice({
  name: "packagings",
  initialState: initState,
  reducers: {
    packagingAdded: (state, action: PayloadAction<Packaging[]>) => {
      state.packagings = {};
      action.payload.forEach((object) => {
        state.packagings[object.id] = object;
      });
    },
    packagingTypeAdded: (state, action: PayloadAction<PackagingType[]>) => {
      state.packagingTypes = {};
      action.payload.forEach((object) => {
        state.packagingTypes[object.id] = object;
      });
    },
    userSelectedPackagingTypeId: (state, action: PayloadAction<string>) => {
      state.userSelectedPackagingTypeId = action.payload;
    },
    selectedPackagingTypeResetted: (state) => {
      state.userSelectedPackagingTypeId = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const { packagingAdded, packagingTypeAdded, userSelectedPackagingTypeId, selectedPackagingTypeResetted } =
  slice.actions;

export const selectPackagingsPerProductId = createSelector(
  [
    (state: RootState) => state.packagings.packagings,
    (state: RootState) => state.packagings.packagingTypes,
    (state: RootState) => state.global.sessionState?.vatGroup ?? undefined,
  ],
  (packagings, packagingTypes, vatGroup) => {
    const productPackagings: Record<string, Packaging[]> = {};
    _.values(packagings).forEach((packaging) => {
      if (vatGroup != null && packaging.packaging_type_id) {
        const packagingType = packagingTypes[packaging.packaging_type_id];
        if (isKiosk()) {
          if (vatGroup == 1 && packagingType && !packagingType.order_mode_eat_in_enabled) {
            return;
          }
          if (vatGroup == 2 && packagingType && !packagingType.order_mode_takeaway_enabled) {
            return;
          }
        }
        if (isQr()) {
          if (vatGroup == 1 && packagingType && !packagingType.order_mode_delivery_enabled) {
            return;
          }
          if (vatGroup == 2 && packagingType && !packagingType.order_mode_takeaway_enabled) {
            return;
          }
        }
      }

      _.keys(packaging.productIds).forEach((productId) => {
        if (productPackagings[productId] == null) {
          productPackagings[productId] = [];
        }
        productPackagings[productId].push(packaging);
      });
    });
    return productPackagings;
  }
);
export const selectPackagingsPerMenukaartId = createSelector(
  [
    (state: RootState) => state.packagings.packagings,
    (state: RootState) => state.packagings.packagingTypes,
    (state) => state.global.sessionState?.vatGroup ?? undefined,
  ],
  (packagings, packagingTypes, vatGroup) => {
    const menukaartPackagings: Record<string, Packaging[]> = {};
    _.values(packagings).forEach((packaging) => {
      if (vatGroup != null && packaging.packaging_type_id) {
        const packagingType = packagingTypes[packaging.packaging_type_id];
        if (vatGroup == 1 && packagingType && !packagingType.order_mode_eat_in_enabled) {
          return;
        }
        if (vatGroup == 2 && packagingType && !packagingType.order_mode_takeaway_enabled) {
          return;
        }
      }

      _.keys(packaging.menukaartIds).forEach((menukaartId) => {
        if (menukaartPackagings[menukaartId] == null) {
          menukaartPackagings[menukaartId] = [];
        }
        menukaartPackagings[menukaartId].push(packaging);
      });
    });
    return menukaartPackagings;
  }
);

export const selectAllPackagingsFromShoppingCartItems = createSelector(
  [
    (state: RootState) => state.packagings.packagingTypes,
    (state: RootState) => state.shoppingCart.items,
    selectArticleArticlegroupsMap,
    selectPackagingsPerProductId,
    selectPackagingsPerMenukaartId,
  ],

  (packagingTypes, items, articleArticlegroupsMap, packagingsPerProduct, packagingsPerArticlegroup) => {
    let totalPackagings: Packaging[] = [];

    function processItem(item: OrderArticle, count: number = 1) {
      item.orderOptionGroups.forEach((orderOptionGroup) => {
        orderOptionGroup.orderArticles.forEach((orderArticle) => {
          if (orderArticle.count > 0) {
            processItem(orderArticle, item.count * count);
          }
        });
      });

      if (packagingsPerProduct[item.article.id]) {
        for (let i = 0; i < item.count * count; i++) {
          totalPackagings.push(...packagingsPerProduct[item.article.id]);
        }
        return;
      }

      if (articleArticlegroupsMap[item.article.id]) {
        for (const articlegroupId of articleArticlegroupsMap[item.article.id]) {
          if (packagingsPerArticlegroup[articlegroupId]) {
            for (let i = 0; i < item.count * count; i++) {
              totalPackagings.push(...packagingsPerArticlegroup[articlegroupId]);
            }
          }
        }
      }
    }

    items.forEach((item) => {
      processItem(item);
    });

    totalPackagings = totalPackagings.filter((packaging) => {
      return !(packaging.packaging_type_id && !packagingTypes[packaging.packaging_type_id]);
    });

    const packagingsPerOrder = totalPackagings.filter((packaging) => packaging.add_price_per_order);
    return _.uniqBy(packagingsPerOrder, (a) => a.id).concat(
      totalPackagings.filter((packaging) => packaging.add_price_per_item || !packaging.product_id)
    );
  }
);

export const selectAllPackagingsTypesFromShoppingCartItems = createSelector(
  [selectAllPackagingsFromShoppingCartItems],
  (packagingsInShoppingCart) => {
    const packagingsPerType: Record<string, Packaging[]> = {};
    packagingsInShoppingCart.forEach((packaging) => {
      if (packaging.packaging_type_id) {
        if (packagingsPerType[packaging.packaging_type_id] == null) {
          packagingsPerType[packaging.packaging_type_id] = [];
        }
        packagingsPerType[packaging.packaging_type_id].push(packaging);
      }
    });
    return packagingsPerType;
  }
);

export const selectAllPackagingsByUserInput = createSelector(
  [
    (state: RootState) => state.packagings.userSelectedPackagingTypeId,
    selectAllPackagingsTypesFromShoppingCartItems,
    selectArticlesMap,
  ],
  (userSelectedPackagingTypeId, packagingsPerType, articlesMap) => {
    const orderArticles: Record<string, OrderArticle> = {};

    if (userSelectedPackagingTypeId && packagingsPerType[userSelectedPackagingTypeId]) {
      packagingsPerType[userSelectedPackagingTypeId].forEach((packaging) => {
        if (packaging.product_id) {
          const article = articlesMap[packaging.product_id];
          if (article) {
            if (!orderArticles[packaging.product_id]) {
              orderArticles[packaging.product_id] = initOrderArticle(articlesMap, article, 1);
            } else {
              orderArticles[packaging.product_id].count++;
            }
          }
        }
      });
    }
    return _.values(orderArticles);
  }
);
export default slice.reducer;
