import { useAppSelector } from "../utils/redux/store";

export default function HiddenIframe() {
  const url = useAppSelector((state) => state.global.hiddenIframeURL);

  if (url) {
    return <iframe key={url} src={url} style={{ display: "none", width: "1px", height: "1px" }} />;
  } else {
    return null;
  }
}
