import Article, { OptionTranslations } from "./Article";

export default interface OptionGroup {
  id: string;
  name: string;
  countable: boolean;
  maxCount: number;
  minCount: number;
  optionIds: string[];
  showMaxNumberOfItems?: number;
  skip: boolean;
  isUpsell: boolean;
  updatedAt: string;
  sortKey: number;
  translations: OptionTranslations;
  default_counts: { product_ids: { [product_id: number]: { count: number } } };
}

export function getPropertyOfOptionGroup(
  optionGroup: OptionGroup,
  article: Article,
  property: "minCount" | "maxCount" | "sortKey"
) {
  return article.optionGroupOverwrites?.[optionGroup.id]?.[property] ?? optionGroup[property];
}
export function getOptionGroupName(optionGroup: OptionGroup, language: string) {
  if (optionGroup.translations?.[language]?.name) {
    return optionGroup.translations?.[language]?.name;
  }

  return optionGroup.name;
}
