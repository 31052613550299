import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store.tsx";
import { VoucherV2 } from "../vouchersV2Slice.tsx";

function findAllVouchers(vouchers: VoucherV2[]): VoucherV2[] {
  for (const voucher of vouchers) {
    if (voucher.subVouchers) {
      vouchers = vouchers.concat(findAllVouchers(voucher.subVouchers));
    }
  }
  return vouchers;
}

export const selectVouchersV2 = createSelector([(state: RootState) => state.vouchersV2.vouchers], (vouchers) => {
  return findAllVouchers(vouchers);
});
