import OpeningHours from "../JamezzHours";

import EanCode, { OracleEanCode } from "./EanCode";
import ArticleTraitValue from "../ArticleTraits/ArticleTraitValue";
import { MediaUrl } from "../../redux/api/menuDataApi";

export enum ArticleType {
  Regular,
  // Not a product, just a text message appearing in your catalogus
  InfoDismissable,
  // Unorderable product acting as a spacer in your catalogus
  Filler,
  // Show article as service request in app
  ServiceRequest,

  Deposit,
}

export enum ArticleOrderTimeState {
  Disabled,
  Hidden,
}

export interface PriceKey {
  discountPercentage?: number;
  type?: string;
  price?: number;
}

/**
 * Not always certain what backoffice will return, so add undefined as optional type
 */
export type OptionTranslations =
  | {
      [language: string]: {
        [key: string]: string;
      };
    }
  | undefined;

export default interface Article {
  id: string;
  apiId1: string;
  name: string;
  hashedName: string;
  printName: string | null;
  imageMediaUrls: MediaUrl[];
  originalPrice: number;
  price: number; // price = original price - discounts
  vaprice: number; // price = original price - discounts
  discounts: Record<string, { discount: number }>;
  propposId: number | string | null;
  blocked: boolean;
  description: string;
  optionGroupIds: string[];
  canOrder: boolean;
  stock: number;
  isOutOfStock?: boolean;
  type: ArticleType;
  orderTimes?: OpeningHours;
  menuTagIds: string[];
  sortKey: number;
  eanCodes?: (EanCode | OracleEanCode)[];
  addExtraArticleIds?: { articleId: string; type: ArticleType }[];
  priceKeys?: Record<string, PriceKey>;
  minCount?: number;
  maxCount: number | null;
  upsellMenu?: string;
  translations: OptionTranslations;
  apiData: any;
  articleTraits?: Record<string, ArticleTraitValue>;
  customData: any;
  requireAge?: number;
  /// Specific for option items
  defaultCount?: number;
  score?: number;
  isTip: boolean;
  updatedAt: string;
  hasCustomizedPriceAndName?: boolean;
  optionGroupOverwrites?: { [optionGroupId: string]: ProductOptionGroupPivot };
  isFoodArticle?: boolean;
  isDrinksArticle?: boolean;
  isNotAvailable: boolean;
  productPriceLines: Record<number, number>;
}

export type ProductOptionGroupPivot = {
  sortKey?: number | null;
  minCount?: number | null;
  maxCount?: number | null;
};
export type ProductOptionGroupPivotApi = ProductOptionGroupPivot & {
  option_group_id: number;
  product_id: number;
  id: number;
  vestiging_id?: number;
};

export function getArticlePrice(
  article: Article,
  options: { salesAreaPriceLineId: number | null } | undefined = undefined
): number {
  if (article.price !== article.originalPrice) {
    return article.price;
  }
  if (options?.salesAreaPriceLineId != null && article.productPriceLines[options.salesAreaPriceLineId] != null) {
    return article.productPriceLines[options.salesAreaPriceLineId] / 100.0;
  }
  return article.price;
}

export function calculateArticlePrice(article: Article) {
  let totalDiscount = 0;
  Object.keys(article.discounts).forEach((discountKey) => {
    totalDiscount += article.discounts[discountKey].discount;
  });
  return article.originalPrice - totalDiscount;
}

export function getArticleName(article: Article, language: string) {
  if (article.translations?.[language]?.naam) {
    return article.translations?.[language]?.naam;
  }

  return article.name;
}

export function getArticleDescription(article: Article, language: string, tryDetailedDescription: boolean = false) {
  if (tryDetailedDescription && article.translations?.[language]?.detailed_description) {
    return article.translations?.[language]?.detailed_description;
  }

  if (article.translations?.[language]?.omschrijving) {
    return article.translations?.[language]?.omschrijving;
  }

  return article.description;
}
