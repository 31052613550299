import { PropsWithChildren, useMemo } from "react";
import { messagesForLanguages, selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage";
import { IntlProvider } from "react-intl";
import { useAppSelector } from "../utils/redux/store.tsx";

export default function JamezzIntlProvider(props: PropsWithChildren) {
  const selectedLanguage = useAppSelector(selectAppLanguage);

  const messages = useMemo(() => {
    return messagesForLanguages[selectedLanguage] ?? messagesForLanguages["en"];
  }, [selectedLanguage]);

  // useEffect(() => {
  //   const element = document.querySelector("html");
  //   if (element) {
  //     element.setAttribute("lang", selectedLanguage);
  //     switch (selectedLanguage) {
  //       case "ar":
  //         element.setAttribute("dir", "rtl");
  //         break;
  //       default:
  //         element.setAttribute("dir", "ltr");
  //         break;
  //     }
  //   }
  // }, [selectedLanguage]);

  return (
    <IntlProvider locale={navigator.language} messages={messages} onError={() => {}}>
      {props.children}
    </IntlProvider>
  );
}

export type DocDir = "rtl" | "ltr";

export function useDocumentDirection(): DocDir {
  return document.querySelector("html")?.getAttribute("dir") === "rtl" ? "rtl" : "ltr";
}
