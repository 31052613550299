import store from "./redux/store";

export const routes = {
  jamezz: { rootPath: "/v5", returnPath: "/v5/return" } as JamezzPaths,
  qr: {
    redirectPath: "/v5/qr",
    rootPath: "/v5/qr/:webcode",
    checkoutPath: "/v5/qr/:webcode/checkout",
    returnPath: "/v5/qr/:webcode/return",
    returnPathAfterPay: "/v5/qr/:webcode/return/afterpay",
    piggyPath: "/v5/qr/:webcode/piggy",
  } as QrPaths,
  kiosk: {
    redirectPath: "/v5/kiosk",
    rootPath: "/v5/kiosk/:webcode",
    menuPath: "/v5/kiosk/:webcode/menu",
    loginPath: "/v5/kiosk/qr/:qr",
    returnPath: "/v5/kiosk/:webcode/return",
    propposPath: "/v5/kiosk/:webcode/proppos",
  } as KioskPaths,
  legacy: { rootPath: "/" },
};

export var jamezzPaths: JamezzPaths = routes.jamezz;

export function initJamezzPaths(paths: JamezzPaths) {
  jamezzPaths = paths;
  window.jamezzPaths = jamezzPaths;
}

export const apiRoutes = {
  qr: {
    checkout: "/v5_2/qr/checkout",
    sendOrder: "/v5_2/qr/order",
    checkOutstandingBalance: "/v5_2/qr/checkOustandingBalance",
  },
  kiosk: {
    activateSession: "/v5_2/kiosk/activateSession",
    checkout: "/v5_2/kiosk/order",
    checkCalculator: "/v5_2/kiosk/checkcalculator",
    getOrderStatus: "/v5_2/kiosk/order",
    checkVoucherCode: "/v5_2/kiosk/checkVoucherCode",
    reinstateVoucherSession: "/v5_2/kiosk/reinstateVoucherSession",
    unknownEancodeFound: "/v5_2/kiosk/unknownEancodeFound",
  },
  piggy: { getBalance: "/v5_2/piggy/contact-balance" },
  schiphol: { getDestination: "/v5_2/schiphol/boarding-pass-destination" },
  global: { data: "/v5_2/qr/data" },
};

export function createPath(path: string) {
  path = path.replace(":webcode", store.getState().global.webcode);
  return path;
}

export function createReturnPath() {
  return createPath(jamezzPaths.returnPath);
}
