import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import md5 from "md5";
import { ProductResponse } from "../api/menuDataApi";

export const selectServiceRequestArticles = createSelector(
  [(state: RootState) => state.menuData.products],
  (products) => {
    const mapping: Record<string, boolean> = {};

    const parse = (apiArticle: ProductResponse) => {
      let settings = apiArticle.settings ? JSON.parse(apiArticle.settings) : {};

      if (settings.isServiceProduct) {
        const articleId = String(apiArticle.id ?? md5(JSON.stringify(apiArticle)));
        mapping[articleId] = true;
      }
    };
    products.forEach(parse);

    return mapping;
  }
);
