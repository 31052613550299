import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface KioskState {
  vatGroups: number[];
  productAddedByBarcodeDialogIsOpen: boolean;
  productAddedByBarcodeDialogIsOpenTimer: ReturnType<typeof setTimeout>;
  wheelchairFriendly: boolean;
}

const initState: KioskState = {
  vatGroups: [1],
  productAddedByBarcodeDialogIsOpen: false,
  productAddedByBarcodeDialogIsOpenTimer: setTimeout(() => {}),

  wheelchairFriendly: false,
};
export const kioskSlice = createSlice({
  name: "kiosk",
  initialState: initState,
  reducers: {
    setProductAddedByBarcodeDialog: (
      state,
      action: PayloadAction<{ isOpen: boolean; timer?: ReturnType<typeof setTimeout> }>
    ) => {
      if (action.payload.isOpen && action.payload.timer) {
        clearTimeout(state.productAddedByBarcodeDialogIsOpenTimer);
        state.productAddedByBarcodeDialogIsOpen = true;
        state.productAddedByBarcodeDialogIsOpenTimer = action.payload.timer;
      } else {
        state.productAddedByBarcodeDialogIsOpen = false;
      }
    },
    kioskResetted: () => {},
    setWheelchairFriendly: (state, action: PayloadAction<boolean>) => {
      state.wheelchairFriendly = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProductAddedByBarcodeDialog, kioskResetted, setWheelchairFriendly } = kioskSlice.actions;

export default kioskSlice.reducer;
