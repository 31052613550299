import OpeningHours, { TimeSpan } from "../../models/JamezzHours";

export default function parseApiTimeTable(apiTimeTable: any, exceptions: any = {}): OpeningHours | undefined {
  if (!apiTimeTable) {
    return undefined;
  }

  if (typeof apiTimeTable === "string") {
    apiTimeTable = JSON.parse(apiTimeTable);
  }

  return {
    monday: parseApiDayTimeTable(apiTimeTable["mo"]),
    tuesday: parseApiDayTimeTable(apiTimeTable["tu"]),
    wednesday: parseApiDayTimeTable(apiTimeTable["we"]),
    thursday: parseApiDayTimeTable(apiTimeTable["th"]),
    friday: parseApiDayTimeTable(apiTimeTable["fr"]),
    saturday: parseApiDayTimeTable(apiTimeTable["sa"]),
    sunday: parseApiDayTimeTable(apiTimeTable["su"]),
    exceptions: exceptions,
  };
}

export function parseApiDayTimeTable(dayTimeTable: any[]): TimeSpan[] {
  if (!dayTimeTable) {
    return [];
  }
  return dayTimeTable.map((hourRange) => {
    const startTimeSplitted = hourRange?.startTime.split(":");
    const endTimeSplitted = hourRange?.stopTime.split(":");

    return {
      startTime: { hours: Number(startTimeSplitted[0]), minutes: Number(startTimeSplitted[1]), seconds: 0 },
      endTime: { hours: Number(endTimeSplitted[0]), minutes: Number(endTimeSplitted[1]), seconds: 0 },
    };
  });
}
