import OrderArticle, { calculateOrderArticlePriceSum } from "../../models/order/OrderArticle";

import { TransactionCostsConfig } from "../globalSlice";
import { getArticlePrice } from "../../models/menu/Article.ts";

export function calculateNewTransactionCosts(
  orderItems: OrderArticle[],
  transactionCostsConfig: TransactionCostsConfig,
  priceLineId: number | null
) {
  const transaction_cost_product = orderItems.find(
    (item) => String(item.article.id) === String(transactionCostsConfig.product_id)
  );

  const orderPrice =
    calculateOrderArticlePriceSum(orderItems, priceLineId) -
    (transaction_cost_product?.article
      ? getArticlePrice(transaction_cost_product.article, { salesAreaPriceLineId: priceLineId })
      : 0);

  if (orderPrice < transactionCostsConfig.max_value) {
    if (transactionCostsConfig.calculation_method === "fixed") {
      return transactionCostsConfig.calculation_value;
    } else if (transactionCostsConfig.calculation_method === "percentage") {
      return orderPrice * (transactionCostsConfig.calculation_value / 100);
    }
  }

  return 0;
}
