export default {
  get: {
    getSessionState: { url: "/v5_2/session/state" },
    setLangInSession: { url: "/v5_2/sessions/setLang" },
  },
  post: {
    loginSession: {
      url: "/v5_2/session/login",
      data: (webcode: string, returnUrl: string, returnOrderId: string, hash: string, cardnr: string) => {
        const formData = new FormData();
        formData.set("webcode", webcode);
        formData.set("returnUrl", returnUrl);
        formData.set("oid", returnOrderId);
        formData.set("hash", hash);
        formData.set("cardnr", cardnr);
        return formData;
      },
    },
  },
};
