import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import OrderArticle from "../models/order/OrderArticle";

type OrderArticleId = OrderArticle["uuid"];

// a tray represents a collection of items scanned using proppos.
export type PropposTray = OrderArticleId[];

const initialState: { [trayId: string]: PropposTray } = {};

export const propposSlice = createSlice({
  name: "proppos",
  initialState,
  reducers: {
    TrayScanned: (state, { payload: tray }: PayloadAction<OrderArticleId[]>) => {
      state[nanoid()] = tray;
    },
    trayDeleted: (state, payload: PayloadAction<string>) => {
      delete state[payload.payload];
    },
    resetProppos: () => {
      return initialState;
    },
  },
});

export const { TrayScanned, trayDeleted, resetProppos } = propposSlice.actions;

export default propposSlice.reducer;
