import { Close } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import { CSSProperties } from "@mui/material/styles/createMixins";

export default function CloseDialogButton({ onClose }: { onClose?: () => void }) {
  const { closeDialog } = useDialog();
  return (
    <IconButton
      data-cy="dialog-back"
      className={"JS-BackDialogButton-Root"}
      sx={{
        zIndex: 100,
        position: "absolute",
        // display: "none",
        top: 0,
        left: 0,
        marginTop: 1,
        marginLeft: 1,
        background: "white",
      }}
      onClick={() => {
        if (onClose) {
          onClose();
        } else {
          closeDialog();
        }
      }}
    >
      <Close color="primary" className={"JS-BackDialogButton-Icon"} />
      {/*<ArrowBack color="primary" className={"JS-BackDialogButton-Icon"} />*/}
    </IconButton>
  );
}

export function FloatingCloseDialogButton({
  onClose,
  visibility,
  float,
}: {
  onClose?: () => void;
  visibility?: CSSProperties["visibility"];
  float?: CSSProperties["float"];
}) {
  const { closeDialog } = useDialog();
  return (
    <IconButton
      data-cy="dialog-back"
      className={"JS-BackDialogButton-Root"}
      sx={{
        zIndex: 100,
        float: float ?? "left",
        // position: "absolute",
        // display: "none",
        m: 1.5,
        background: "white",
        visibility,
      }}
      onClick={() => {
        if (onClose) {
          onClose();
        } else {
          closeDialog();
        }
      }}
    >
      <Close color="primary" className={"JS-BackDialogButton-Icon"} />
      {/*<ArrowBack color="primary" className={"JS-BackDialogButton-Icon"} />*/}
    </IconButton>
  );
}
