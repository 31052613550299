import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TraitFilterState {
  isFilterPageOpen: boolean;
  stateOfArticleTraits: Record<string, string[]>; // {[traitName: string]: string[]}
}

const initState: TraitFilterState = {
  isFilterPageOpen: false,
  stateOfArticleTraits: {},
};

export const traitFilterSlice = createSlice({
  name: "traitFilter",
  initialState: initState,
  reducers: {
    setIsFilterPageOpen: (state, action: PayloadAction<boolean>) => {
      state.isFilterPageOpen = action.payload;
    },
    setStateOfArticleTraits: (state, action) => {
      state.stateOfArticleTraits = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsFilterPageOpen, setStateOfArticleTraits } = traitFilterSlice.actions;

export default traitFilterSlice.reducer;
