import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

// Find all campaigns for an article with O(1) performance
export const selectArticleCampaignsMap = createSelector(
  [(state: RootState) => state.menuData.campaign_products],
  (campaign_products) => {
    const articleIdCampaignIdsMap: Record<string, string[]> = {};

    campaign_products.forEach((pivot) => {
      if (!articleIdCampaignIdsMap[pivot.product_id]) {
        articleIdCampaignIdsMap[pivot.product_id] = [];
      }
      articleIdCampaignIdsMap[pivot.product_id].push(pivot.campaign_id);
    });
    return articleIdCampaignIdsMap;
  }
);
