/**
 * Return something like:
 *              {
 *               "@font-face": {
 *                 fontFamily: "AmsiPro-Regular",
 *                 src: "url('https://jamezz.blob.core.windows.net/public-media/fonts/files/AmsiPro-Regular.otf')",
 *                 fontWeight: "normal",
 *                 fontDisplay: "swap",
 *                 fontStyle: "normal",
 *               },
 *             }
 */

export default function convertCustomFonts(customFonts: any) {
  if (customFonts) {
    customFonts = JSON.parse(customFonts);

    return customFonts.map((customFont: { full_url?: string; fontFamily: string }) => {
      const fontPath = customFont.full_url;

      return convertToFontFaceFromBase(customFont.fontFamily, `url('${fontPath}')`, "normal", "swap", "normal");
    });
  }
  return [];
}

export function convertToFontFaceFromBase(
  fontFamily: string,
  src: string,
  fontWeight: string | number,
  fontDisplay: string,
  fontStyle: string
) {
  return { "@font-face": { fontFamily, src, fontWeight, fontDisplay, fontStyle } };
}
