import { FormattedMessage } from "react-intl";
import { Props } from "react-intl/lib/src/components/message";
import { defaultMessages } from "../../kiosk/components/LanguageSelector/useLanguage";
import defaults from "../../assets/langs/defaults.json";

export type JamezzTranslation = keyof typeof defaults;
export type JamezzTranslationId = { id: JamezzTranslation };

export default function FormattedMessageJamezz(props: Omit<Props, "id"> & JamezzTranslationId) {
  return (
    <FormattedMessage
      {...props}
      values={{
        ...props.values,
        b: (chunks) => <b>{chunks}</b>,
        // in intl, you need to use open and closing tags for br like so:
        // <br></br>
        br: () => <br />,
      }}
      defaultMessage={defaultMessages[props.id] as string}
    />
  );
}
