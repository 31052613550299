import { useRef } from "react";
import DialogProvider from "../global/utils/dialog/DialogProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import ScrollProvider from "../global/utils/ScrollProvider";
import { BrowserRouter } from "react-router-dom";
import HiddenIframe from "../global/components/HiddenIframe";
import JamezzIntlProvider from "../global/components/JamezzIntlProvider";
import JamezzTheme from "../global/components/Theming/JamezzTheme";
import "../assets/HideScrollBars.css";
import queryString from "query-string";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { ApplicationType } from "../global/utils/useSessionState";
import { useAppSelector } from "../global/utils/redux/store";
import { IntlShape } from "react-intl";
import ForceOpenSnackbar from "../global/components/DevComponents/ForceOpenSnackbar.tsx";
import ForceDateAndTimeSnackbar from "../global/components/DevComponents/ForceDateAndTimeSnackbar.tsx";
import isKiosk from "../global/utils/helpers/isKiosk.tsx";
import { BlockUserInput } from "@jamezz/react-components";

export var globalIntl: { object: IntlShape | null } = { object: null };
interface Props {
  children: any;
  theme: any;
  applicationType: ApplicationType;
}

export const jamezzApplicationType: { type: null | ApplicationType } = { type: null };
window.jamezzApplicationType = jamezzApplicationType;

function App(props: Props) {
  const scrollContent = useRef(null);
  const refreshApp = useAppSelector((state) => state.global.refreshApp);

  jamezzApplicationType.type = props.applicationType;
  return (
    // <Profiler>
    <ScrollProvider scrollContent={scrollContent}>
      {/*<ErrorBoundary>*/}
      <Box
        sx={{ width: 1, height: isKiosk() ? 1 : undefined, minHeight: 1, display: "flex", flexDirection: "column" }}
        key={refreshApp}
      >
        <HiddenIframe />
        <BrowserRouter>
          <QueryParamProvider
            adapter={ReactRouter6Adapter}
            options={{
              searchStringToObject: queryString.parse,
              objectToSearchString: queryString.stringify,
            }}
          >
            <JamezzIntlProvider>
              <JamezzTheme>
                <DialogProvider>
                  {props.children}
                  <ForceOpenSnackbar />
                  <ForceDateAndTimeSnackbar />
                  <BlockUserInput.View />
                </DialogProvider>
                <ToastContainer
                  theme={"colored"}
                  position={"bottom-right"}
                  autoClose={5000}
                  newestOnTop={false}
                  closeOnClick
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </JamezzTheme>
            </JamezzIntlProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </Box>
      {/*</ErrorBoundary>*/}
    </ScrollProvider>
    // </Profiler>
  );
}

export default App;
