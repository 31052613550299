import OrderArticle, { getTotalPrice } from "../../models/order/OrderArticle";
import Campaign from "../../models/campaigns/Campaign";

export default function calculateXForYCampaign(
  campaign: Campaign,
  orderArticles: OrderArticle[],
  priceLineId: number | null
) {
  let discountAmount = 0;

  if (campaign.Xs?.length === 2) {
    orderArticles = orderArticles.sort(
      (a, b) =>
        getTotalPrice(a, priceLineId, { count: 1, includeOptions: false, includeExtraOrderArticles: false }) -
        getTotalPrice(b, priceLineId, { count: 1, includeOptions: false, includeExtraOrderArticles: false })
    );
    const totalCount = orderArticles.reduce((sum, orderArticle) => {
      return sum + orderArticle.count;
    }, 0);
    const numberOfItemsShouldBeDiscounted = Math.floor(totalCount / campaign.Xs[0]) * campaign.Xs[0];
    const pricePerUnit = campaign.Xs[1] / campaign.Xs[0];

    let numberOfItemsDiscounted = 0;
    orderArticles.some((orderArticle) => {
      if (numberOfItemsDiscounted + orderArticle.count <= numberOfItemsShouldBeDiscounted) {
        discountAmount +=
          (getTotalPrice(orderArticle, priceLineId, {
            count: 1,
            includeOptions: false,
            includeExtraOrderArticles: false,
          }) -
            pricePerUnit) *
          orderArticle.count;
        numberOfItemsDiscounted += orderArticle.count;
        return false;
      } else {
        const count = numberOfItemsShouldBeDiscounted - numberOfItemsDiscounted;
        discountAmount +=
          (getTotalPrice(orderArticle, priceLineId, {
            count: 1,
            includeOptions: false,
            includeExtraOrderArticles: false,
          }) -
            pricePerUnit) *
          count;
        numberOfItemsDiscounted += count;
        return true;
      }
    });
  }
  return discountAmount;
}
