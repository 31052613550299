import Articlegroup from "./Articlegroup";
import Article from "./Article";
import OptionGroup from "./OptionGroup";
import Campaign from "../campaigns/Campaign";
import store from "../../redux/store";

export function getArticlegroupById(
  articlegroupsMap: Record<string, Articlegroup>,
  articlegroupId: string
): Articlegroup | null {
  return articlegroupsMap[articlegroupId] ?? null;
}

export function getArticlegroupsById(
  articlegroupsMap: Record<string, Articlegroup>,
  articlegroupIds: string[]
): Articlegroup[] {
  const articlegroups: Articlegroup[] = [];
  articlegroupIds.forEach((articlegroupId) => {
    const articlegroup = getArticlegroupById(articlegroupsMap, articlegroupId);
    if (articlegroup) {
      articlegroups.push(articlegroup);
    }
  });
  return articlegroups;
}

export function getArticleById(articlesMap: Record<string, Article>, articleId: string): Article | null {
  return articlesMap[articleId] ?? null;
}

export function getArticleByHash(articleHashNamesMap: Record<string, Article>, hashName: string): Article | null {
  return articleHashNamesMap[hashName] ?? null;
}

export function getArticlesById(articlesMap: Record<string, Article>, articleIds: string[]): Article[] {
  const articles: Article[] = [];
  articleIds.forEach((articleId) => {
    const article = getArticleById(articlesMap, articleId);
    if (article) {
      articles.push(article);
    }
  });
  return articles;
}

export function getArticlegroupsBySupergroupId(
  articlegroupsMap: Record<string, Articlegroup>,
  articlegroupChildrenMap: Record<string, string[]>,
  supergroupId: string
): Articlegroup[] {
  return (
    articlegroupChildrenMap[supergroupId]
      ?.map((articlegroupId) => articlegroupsMap[articlegroupId])
      .filter((a) => a)
      .filter(
        (a) =>
          a.parentId == null ||
          !store.getState().global.salesarea.custom_data?.appv5_use_articlegroups_hierarchically?.enabled
      ) ?? []
  ); // Filter not existing articlegroups
}

export function getArticlesByArticlegroupId(
  articlesMap: Record<string, Article>,
  articlegroupArticlesMap: Record<string, string[]>,
  articlegroupId: string
): Article[] {
  return getArticlesByArticlegroupIds(articlesMap, articlegroupArticlesMap, [articlegroupId]);
}

export function getArticlesByArticlegroupIds(
  articlesMap: Record<string, Article>,
  articlegroupArticlesMap: Record<string, string[]>,
  articlegroupIds: string[]
): Article[] {
  return articlegroupIds.reduce((articles: Article[], articlegroupId) => {
    articlegroupArticlesMap[articlegroupId]?.forEach((articleId) => {
      if (articlesMap[articleId]) {
        articles.push(articlesMap[articleId]);
      }
    });
    return articles;
  }, []);
}

export function getCampaignsByArticleId(
  campaignsMap: Record<string, Campaign>,
  articleCampaignsMap: Record<string, string[]>,
  articleId: string
): Campaign[] {
  return (
    articleCampaignsMap[articleId]
      ?.map((campaignId) => {
        return campaignsMap[campaignId];
      })
      .filter((campaign) => campaign) ?? [] // Filter not existing campaigns
  );
}

export function getOptionGroupsByArticleId(
  articlesMap: Record<string, Article>,
  optionGroupsMap: Record<string, OptionGroup>,
  articleId: string
): OptionGroup[] {
  return (
    (getArticleById(articlesMap, articleId)
      ?.optionGroupIds?.map((optionGroupId) => optionGroupsMap[optionGroupId] ?? null)
      .filter((optionGroup) => optionGroup) as OptionGroup[]) ?? []
  );
}
