import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import _ from "lodash";

export const selectArticleArticlegroupsMapUnfiltered = createSelector(
  [(state: RootState) => state.menuData.menukaart_products],
  (menukaartProducts) => {
    const map: Record<string, Record<string, boolean>> = {};
    menukaartProducts.forEach((menukaartProduct) => {
      if (!map[menukaartProduct.product_id]) {
        map[menukaartProduct.product_id] = {};
      }

      map[menukaartProduct.product_id][menukaartProduct.menukaart_id] = true;
    });

    return _.chain(map)
      .mapValues((record) => {
        return _.keys(record);
      })
      .value();
  }
);
