import OrderArticle, { getTotalPrice } from "../../models/order/OrderArticle";
import Campaign from "../../models/campaigns/Campaign";

export default function calculateBuyXPayYCampaign(
  campaign: Campaign,
  orderArticles: OrderArticle[],
  priceLineId: number | null
) {
  let discountAmount = 0;

  if (campaign.Xs?.length === 2) {
    orderArticles = orderArticles.sort(
      (a, b) =>
        getTotalPrice(a, priceLineId, { count: 1, includeOptions: false, includeExtraOrderArticles: false }) -
        getTotalPrice(b, priceLineId, { count: 1, includeOptions: false, includeExtraOrderArticles: false })
    );
    const totalCount = orderArticles.reduce((sum, orderArticle) => {
      return sum + orderArticle.count;
    }, 0);
    const occurrences = Math.floor(totalCount / campaign.Xs[0]);

    const numberOfFreeItems = occurrences * (campaign.Xs[0] - campaign.Xs[1]);

    let numberOfItemsDiscounted = 0;
    orderArticles.some((orderArticle) => {
      if (numberOfItemsDiscounted + orderArticle.count <= numberOfFreeItems) {
        discountAmount += getTotalPrice(orderArticle, priceLineId, {
          includeOptions: false,
          includeExtraOrderArticles: false,
        });
        numberOfItemsDiscounted += orderArticle.count;
        return false;
      } else {
        const count = numberOfFreeItems - numberOfItemsDiscounted;
        discountAmount += getTotalPrice(orderArticle, priceLineId, {
          count,
          includeOptions: false,
          includeExtraOrderArticles: false,
        });
        numberOfItemsDiscounted += count;
        return true;
      }
    });
  }
  return discountAmount;
}
