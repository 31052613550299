import { startAppListening } from "../listenerMiddleware";
import isQr from "../../helpers/isQr";
import OrderArticle, { OrderArticleOrigin } from "../../models/order/OrderArticle";
import { sharedShoppingCartTransactionsProcessed } from "../shoppingCartSlice";
import { shoppingCartApi, Transaction } from "../api/shoppingCartApi";
import { v4 as uuidv4 } from "uuid";
import store from "../store";

export function sharedShoppingCartListenerAddItem() {
  // console.log("START LISTENER SSC");
  startAppListening({
    predicate: (__, currentState) => {
      return (
        isQr() &&
        currentState.shoppingCart.isSharedShoppingCartEnabled &&
        !store.getState().global.salesarea.payDirect &&
        __.type === "shoppingCart/orderArticlesPushedByUser"
      );
    },
    effect: (action: any, { dispatch }) => {
      const transactions: Transaction[] = action.payload
        .filter((orderArticle: OrderArticle) => orderArticle.added_origin === OrderArticleOrigin.MENU)
        .map((orderArticle: OrderArticle) => {
          const uuid = uuidv4() as string;
          return {
            uuid,
            orderArticle,
          };
        });

      dispatch(sharedShoppingCartTransactionsProcessed(transactions));
      dispatch(
        shoppingCartApi.endpoints.addTransactionsToSharedShoppingCart.initiate({
          webcode: store.getState().global.webcode,
          transactions: transactions,
        })
      );
    },
  });
}
