import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { mwiseApi } from "./api/MWiseApi";
import { toast } from "react-toastify";
import { startAppListening } from "./listenerMiddleware";
import _ from "lodash";
import { setSalesarea } from "./globalSlice";
import { orderSucceeded } from "./shoppingCartSlice";
import { MWiseState } from "../../../types/mwise";

const initialState: MWiseState = {
  user: null,
  claimedReward: null,
};

export const mwiseSlice = createSlice({
  name: "mwise",
  initialState,
  reducers: {
    rewardClaimed: (state, { payload: reward }: PayloadAction<{ oracleDiscountId: number; mwiseCode: string }>) => {
      state.claimedReward = reward;
    },
    rewardCanceled: (state) => {
      state.claimedReward = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(orderSucceeded, (state) => {
        state.claimedReward = null;
      })
      .addCase(setSalesarea, (state, { payload: { id: salesareaId } }) => {
        if (!state.claimedReward) {
          const rememberedValue = localStorage.getItem(`V5.mwise.claimedReward.${salesareaId}`);
          if (rememberedValue) {
            state.claimedReward = JSON.parse(rememberedValue);
          }
        }
      })
      .addMatcher(mwiseApi.endpoints.login.matchFulfilled, (state, { payload: { data: user } }) => {
        state.user = user;
      })
      .addMatcher(mwiseApi.endpoints.session.matchFulfilled, (state, { payload }) => {
        if (payload.has_session) {
          if (!state.user && payload.card_number && payload.name) {
            state.user = {
              // e-mail address is not used in the application at the moment.
              EmailAddress: "not specified",
              CardNumber: payload.card_number,
              FirstName: payload.name,
            };
          }
        } else {
          // user is logged out, reset all state.
          state.user = null;
          state.claimedReward = null;
        }
      }),
});

startAppListening({
  predicate: (__, currentState, previousState) => {
    return previousState.mwise.user !== null && currentState.mwise.user === null;
  },
  effect: () => {
    // TODO: @Laura translate
    toast.warning("You have been logged out from mwise, please log in again if you want to claim your reward.");
  },
});

startAppListening({
  predicate: (__, currentState, previousState) => {
    return !_.isEqual(previousState.mwise.claimedReward, currentState.mwise.claimedReward);
  },
  effect: (__, { getState }) => {
    const state = getState();
    const salesareaId = state.global.salesarea.id;
    localStorage.setItem(`V5.mwise.claimedReward.${salesareaId}`, JSON.stringify(state.mwise.claimedReward));
  },
});

export const { rewardClaimed, rewardCanceled } = mwiseSlice.actions;

export default mwiseSlice.reducer;
