import { httpGet } from "./http";
import JamezzApi from "./JamezzApi";
import store from "../redux/store";

import { setSessionState } from "../redux/globalSlice";
import { customFieldsResponseRetrieved } from "../redux/customFieldsSlice";

export function setLanguageInSession(language: string) {
  const searchParams = new URLSearchParams();
  searchParams.set("lang", language);
  httpGet(JamezzApi.get.setLangInSession.url + "?" + searchParams.toString()).then((response: any) => {
    const sessionState = store.getState().global.sessionState;
    const salesarea = store.getState().global.salesarea;
    if (sessionState) {
      store.dispatch(setSessionState({ ...sessionState, ...response.data.data }));
    } else {
      store.dispatch(setSessionState(response.data.data));
    }

    if (salesarea) {
      store.dispatch(customFieldsResponseRetrieved(response.data.data.customFields));
    }
  });
}
