import { createSlice } from "@reduxjs/toolkit";
import MenuTag from "../../../global/utils/models/menu/MenuTag";
import OrderHours from "../models/menu/OrderHours";

const collapsedArticlesJson = sessionStorage.getItem("V5.menuState.collapsedArticles");
let collapsedArticles: Record<string, boolean> = {};
if (collapsedArticlesJson != null) {
  collapsedArticles = JSON.parse(collapsedArticlesJson) as Record<string, boolean>;
}

const menuTagPreferencesJson = sessionStorage.getItem("V5.menuState.menuTagPreferences");
let menuTagPreferences: { [key: string]: { menuTag: MenuTag; checked: boolean } } = {};
if (menuTagPreferencesJson != null) {
  menuTagPreferences = JSON.parse(menuTagPreferencesJson) as {
    [key: string]: { menuTag: MenuTag; checked: boolean };
  };
}

interface MenuState {
  collapsedArticles: Record<string, boolean>;
  menuTagPreferences: { [key: string]: { menuTag: MenuTag; checked: boolean } };

  articleOrderHours: OrderHours;
  articlegroupOrderHours: OrderHours;
}

const initState: MenuState = {
  collapsedArticles: collapsedArticles,
  articleOrderHours: {},
  articlegroupOrderHours: {},
  menuTagPreferences: menuTagPreferences,
};

export const menuStateSlice = createSlice({
  name: "menuState",
  initialState: initState,
  reducers: {
    setMenuState: (state, action) => {
      if (action.payload.articleOrderHours) {
        state.articleOrderHours = action.payload.articleOrderHours;
      }
      if (action.payload.articlegroupOrderHours) {
        state.articlegroupOrderHours = action.payload.articlegroupOrderHours;
      }
    },
    setCollapseState: (state, action) => {
      // @ts-ignore
      state.collapsedArticles[action.payload.articleId] = action.payload.collapsed;
      sessionStorage.setItem("V5.menuState.collapsedArticles", JSON.stringify(state.collapsedArticles));
    },
    setMenuTagPreferences: (state, action) => {
      state.menuTagPreferences[action.payload.id] = action.payload.value;
      sessionStorage.setItem("V5.menuState.menuTagPreferences", JSON.stringify(state.menuTagPreferences));
    },
    setAllMenuTagPreferences: (state, action) => {
      state.menuTagPreferences = action.payload;
      sessionStorage.setItem("V5.menuState.menuTagPreferences", JSON.stringify(state.menuTagPreferences));
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMenuState, setCollapseState, setMenuTagPreferences, setAllMenuTagPreferences } =
  menuStateSlice.actions;

export default menuStateSlice.reducer;
