import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { selectArticlegroupsMap } from "./selectArticlegroupsMap";
import { CustomizableTextResponse } from "../api/menuDataApi.ts";

export const selectCustomizableTexts = createSelector([selectArticlegroupsMap], (articlegroupsMap) => {
  const texts: {
    [model: string]: { [modelId: string | number]: { [translationKey: string]: { [languageCode: string]: string } } };
  } = {};

  _.values(articlegroupsMap).forEach((articlegroup) => {
    articlegroup.customizable_texts.forEach((customizableText: CustomizableTextResponse) => {
      if (!texts[customizableText.translatable_type]) {
        texts[customizableText.translatable_type] = {};
      }
      if (!texts[customizableText.translatable_type][customizableText.translatable_id]) {
        texts[customizableText.translatable_type][customizableText.translatable_id] = {};
      }
      if (
        !texts[customizableText.translatable_type][customizableText.translatable_id][customizableText.translation_key]
      ) {
        texts[customizableText.translatable_type][customizableText.translatable_id][customizableText.translation_key] =
          {};
      }
      texts[customizableText.translatable_type][customizableText.translatable_id][customizableText.translation_key][
        customizableText.language_code
      ] = customizableText.text;
    });
  });

  return texts;
});

export function parseCustomizableTexts(customizableTexts: CustomizableTextResponse[]) {
  const texts: { [languageCode: string]: string } = {};
  customizableTexts.forEach((customizableText: CustomizableTextResponse) => {
    texts[customizableText.language_code] = customizableText.text;
  });
  return texts;
}
