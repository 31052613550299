import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store.tsx";
import { calculateOrderArticlePriceSum } from "../../models/order/OrderArticle.ts";

import { selectPiggyVouchersDiscountValue } from "./selectPiggyVouchersDiscountValue.tsx";
import { selectSalesAreaPriceLineId } from "../../useSalesAreaPriceLineId.ts";

export const selectItemsOrderValue = createSelector(
  [(state: RootState) => state.shoppingCart.items, selectPiggyVouchersDiscountValue, selectSalesAreaPriceLineId],
  (items, piggyDiscountValue, priceLineId) => {
    return calculateOrderArticlePriceSum(items, priceLineId) - piggyDiscountValue;
  }
);
