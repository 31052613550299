import { createContext, MutableRefObject, useContext, useRef } from "react";

const ScrollContext = createContext<{ scrollContent: any; scrollTabBlocked: any; resetScroll: () => void }>({
  scrollTabBlocked: undefined,
  scrollContent: undefined,
  resetScroll: () => {},
});

export const useScroll = () => useContext(ScrollContext);

export default function ScrollProvider({
  scrollContent,
  children,
}: {
  scrollContent: MutableRefObject<any>;
  children: any;
}) {
  const scrollTabBlocked = useRef(false);
  return (
    <ScrollContext.Provider
      value={{
        scrollContent,
        scrollTabBlocked,
        resetScroll: () => {
          scrollContent.current = null;
          scrollTabBlocked.current = false;
        },
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
}
