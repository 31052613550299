export default interface Currency {
  id: string;
  name: string;
  symbol: string;
}

export const currencies: Record<string, Currency> = {
  ANG: { id: "ANG", name: "Antilliaanse gulden", symbol: "ANG" },
  AUD: { id: "AUD", name: "AUD", symbol: "$" },
  CAD: { id: "CAD", name: "CAD", symbol: "$" },
  CHF: { id: "CHF", name: "Zwitserse frank", symbol: "CHF" },
  CNY: { id: "CNY", name: "Renminbi", symbol: "¥" },
  DKK: { id: "DKK", name: "Deense kroon", symbol: "kr" },
  EUR: { id: "EUR", name: "Euro", symbol: "€" },

  GBP: { id: "GBP", name: "Britse pond", symbol: "£" },

  JPY: { id: "JPY", name: "JPY", symbol: "¥" },

  NOK: { id: "NOK", name: "Noorse kroon", symbol: "kr" },
  SEK: { id: "SEK", name: "Zweedse kroon", symbol: "kr" },

  USD: { id: "USD", name: "Amerikaanse dollar", symbol: "$" },
  ZAR: { id: "ZAR", name: "ZAR", symbol: "R" },
};
