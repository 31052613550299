import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { orderSucceeded } from "./shoppingCartSlice";

interface CikamState {
  transactionId?: string;
  paymentAmount?: number;
}

export enum CikamStatusCode {
  "Initializing" = 0,
  "Idle" = 1,
  "At starting change" = 2,
  "Waiting insertion of cash" = 3,
  "Counting" = 4,
  "Dispensing" = 5,
  "Waiting removal of cash in reject" = 6,
  "Waiting removal of cash out" = 7,
  "Resetting" = 8,
  "Canceling of Change operation" = 9,
  "Calculating Change amount" = 10,
  "Canceling Deposit" = 11,
  "Collecting" = 12,
  "Error" = 13,
  "Upload firmware" = 14,
  "Reading log" = 15,
  "Waiting Replenishment" = 16,
  "Counting Replenishment" = 17,
  "Unlocking" = 18,
  "Waiting inventory" = 19,
  "Fixed deposit amount" = 20,
  "Fixed dispense amount" = 21,
  "Waiting for Error recovery" = 30,
}

export enum CikamTransactionStatus {
  Success = 0,
  Cancel = 1,
  Reset = 2,
}

const paymentAmount = sessionStorage.getItem("V5.cikam.paymentAmount");

const initialState: CikamState = {
  transactionId: sessionStorage.getItem("V5.cikam.transactionId") ?? undefined,
  paymentAmount: paymentAmount ? Number(paymentAmount) : undefined,
};

export const cikamSlice = createSlice({
  name: "cikam",
  initialState,
  reducers: {
    cikamTransactionStarted: (state, action: PayloadAction<{ transactionId: string; paymentAmount: number }>) => {
      state.transactionId = action.payload.transactionId;
      state.paymentAmount = action.payload.paymentAmount;

      sessionStorage.setItem("V5.cikam.transactionId", action.payload.transactionId);
      sessionStorage.setItem("V5.cikam.paymentAmount", String(action.payload.paymentAmount));
    },
    resetCikamTransaction: (state) => {
      state.transactionId = undefined;
      state.paymentAmount = undefined;
      sessionStorage.removeItem("V5.cikam.transactionId");
      sessionStorage.removeItem("V5.cikam.paymentAmount");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(orderSucceeded, (state, action) => {
      state.transactionId = undefined;
      state.paymentAmount = undefined;
      sessionStorage.removeItem("V5.cikam.transactionId");
      sessionStorage.removeItem("V5.cikam.paymentAmount");
    });
  },
});

export const { cikamTransactionStarted, resetCikamTransaction } = cikamSlice.actions;
export default cikamSlice.reducer;
