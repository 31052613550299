import { createSelector } from "@reduxjs/toolkit";
import { ArticleAddedToShoppingCartRule } from "../../../automation/Rule.ts";
import { RootState } from "../../store.tsx";
import { getTranslations } from "../../../api/parseApis/useParseApiArticle.ts";
import { parseCustomizableTexts } from "../selectCustomizableTexts.ts";
import { selectTimeSchedules } from "../../../../domains/timeSchedules/selectors/selectTimeSchedules.ts";
import { getNow } from "../../../models/JamezzHours.ts";
import { getDay, isAfter, setHours, setMinutes, setSeconds } from "date-fns";
import { weekDaysDateFns } from "../../../../../types/shared/TimeSchedule.ts";

export const selectArticleAddedToShoppingCartRules = createSelector(
  [(state: RootState) => state.menuData.products, (state: RootState) => state.menuData.upsells, selectTimeSchedules],
  (products, upsells, timeSchedules) => {
    const articleAddedToShoppingCartRules: ArticleAddedToShoppingCartRule[] = [];

    products.forEach((apiArticle) => {
      const articleId = apiArticle.id;

      let translations = {};
      if (apiArticle.translations) {
        translations = JSON.parse(apiArticle.translations);
      }

      if (apiArticle.freeOptions) {
        parseFreeOptions(
          articleId,
          apiArticle.freeOptions,
          apiArticle.freeOptionsTitle ?? "",
          getTranslations(translations, "freeOptionsTitle"),
          articleAddedToShoppingCartRules
        );
      }
      if (apiArticle.freeOptions2) {
        parseFreeOptions(
          articleId,
          apiArticle.freeOptions2,
          apiArticle.freeOptionsTitle2 ?? "",
          getTranslations(translations, "freeOptionsTitle2"),
          articleAddedToShoppingCartRules
        );
      }
      if (apiArticle.freeOptions3) {
        parseFreeOptions(
          articleId,
          apiArticle.freeOptions3,
          apiArticle.freeOptionsTitle3 ?? "",
          getTranslations(translations, "freeOptionsTitle3"),
          articleAddedToShoppingCartRules
        );
      }
    });

    for (const upsell of upsells) {
      for (const flow of upsell.flows) {
        for (const page of flow.pages) {
          const rule: ArticleAddedToShoppingCartRule = {
            trigger: {
              articleIds: upsell.trigger_products.map((p) => String(p.id)),
              menuIds: upsell.trigger_menus.map((m) => String(m.id)),
            },
            action: {
              discriminator: "ShowArticleDialogAction",
              articleIds: page.products.map((product) => String(product.id)),
              title: page.name[0]?.text ?? "",
              titleTranslations: parseCustomizableTexts(page.name),
              descriptionTranslations: parseCustomizableTexts(page.description),
              upsellId: upsell.id,
            },
            conditions: [
              {
                check: () => {
                  if (flow.time_schedule?.[0] == null) {
                    return true;
                  }
                  const timeSchedule = timeSchedules[flow.time_schedule?.[0].id];
                  if (timeSchedule == null) {
                    return false;
                  }

                  const timeSpansOfDay = timeSchedule.opening_hours.rawData[weekDaysDateFns[getDay(getNow())]];
                  return timeSpansOfDay.some((timeSpan) => {
                    const [startTime, endTime] = timeSpan.split("-");
                    const [startHours, startMinutes, startSeconds = "0"] = startTime.split(":");
                    const [endHours, endMinutes, endSeconds = "0"] = endTime.split(":");
                    const startDate = setSeconds(
                      setMinutes(setHours(getNow(), Number(startHours)), Number(startMinutes)),
                      Number(startSeconds)
                    );
                    const endDate = setSeconds(
                      setMinutes(setHours(getNow(), Number(endHours)), Number(endMinutes)),
                      Number(endSeconds)
                    );
                    return isAfter(getNow(), startDate) && isAfter(endDate, getNow());
                  });
                },
              },
            ],
          };

          articleAddedToShoppingCartRules.push(rule);
        }
      }
    }

    return articleAddedToShoppingCartRules;
  }
);

function parseFreeOptions(
  articleId: string,
  freeOptions: string,
  freeOptionsTitle: string,
  translationsTitle: Record<string, string>,
  articleAddedToShoppingCartRules: ArticleAddedToShoppingCartRule[]
) {
  if (freeOptions === "[]") {
    return;
  }

  const rule: ArticleAddedToShoppingCartRule = {
    trigger: { articleIds: [articleId] },
    action: {
      discriminator: "ShowArticleDialogAction",
      articleIds: JSON.parse(freeOptions),
      title: freeOptionsTitle,
      titleTranslations: translationsTitle,
    },
    conditions: [],
  };

  articleAddedToShoppingCartRules.push(rule);

  return rule;
}
