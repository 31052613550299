import { RootState } from "./redux/store.tsx";
import { selectOrderModeCustomField } from "./redux/customFieldsSlice.ts";
import { createSelector } from "@reduxjs/toolkit";

export const selectSalesAreaPriceLineId = createSelector(
  [selectOrderModeCustomField, (state: RootState) => state.customFields.orderCustomFields],
  (customField, orderCustomFields) => {
    const option = customField?.options?.findIndex((option) => {
      return option.id == orderCustomFields[customField?.name]?.value;
    });
    if (option !== undefined && option >= 0) {
      if (customField?.options?.[option]?.salesAreaPriceLineId) {
        return customField?.options[option]?.salesAreaPriceLineId ?? null;
      }
    }
    return null;
  }
);
