import { startAppListening } from "../listenerMiddleware";
import OrderArticle, { getTotalPrice, orderArticlesAreEqual } from "../../models/order/OrderArticle";
import { getOptionData, postAnalyticsEvent, postEcomAnalyticsEvent } from "../../analytics/useAnalytics";
import { removeBasketProduct, setBasketProduct } from "../aiDataSlice.tsx";

export function orderArticlesAddedListener() {
  startAppListening({
    predicate: (__) => {
      return __.type === "shoppingCart/orderArticlesPushedByUser";
    },
    effect: (action: any, { dispatch, getState }) => {
      action.payload.forEach((orderArticle: OrderArticle) => {
        /**
         * Basket analyse
         */
        const aiData = getState().aiData.rows ?? null;
        if (aiData && orderArticle.count > 0) {
          const currentBasket = getState().shoppingCart.items;
          currentBasket.forEach((oa: OrderArticle) => {
            dispatch(setBasketProduct({ orderArticle: oa, lastUpsellSource: orderArticle.upsellType }));
          });
        } else if (orderArticle.count < 0) {
          dispatch(removeBasketProduct({ orderArticle: orderArticle, lastUpsellSource: orderArticle.upsellType }));
        }

        /**
         * Google Analytics
         */
        const itemIndex = getState().shoppingCart.items.findIndex((item: OrderArticle) =>
          orderArticlesAreEqual(item, orderArticle)
        );
        const item = getState().shoppingCart.items[itemIndex] ?? null;
        if (item) {
          postAnalyticsEvent({
            category: "OrderArticleContent",
            action: "addItemTransactionally(" + item.article.name + ", " + orderArticle.count + ")",
            label: item.article.name + "(" + orderArticle.count + ")",
          });

          if (orderArticle.count > 0) {
            postEcomAnalyticsEvent("add_to_cart", {
              value: getTotalPrice(item, null),
              items: [
                {
                  item_id: item.article.id,
                  item_name: item.article.name,
                  item_variant: getOptionData(item),
                  item_list_name: item?.articlegroup?.name ?? "n/a",
                  item_category: item?.articlegroup?.category ?? "n/a",
                  price: getTotalPrice(item, null),
                  quantity: item.count,
                },
              ],
            });
          } else {
            postEcomAnalyticsEvent("remove_from_cart", {
              value: getTotalPrice(item, null),
              items: [
                {
                  item_id: item.article.id,
                  item_name: item.article.name,
                  item_variant: getOptionData(item),
                  item_list_name: item?.articlegroup?.name ?? "n/a",
                  item_category: item?.articlegroup?.category ?? "n/a",
                  price: getTotalPrice(item, null),
                  quantity: item.count,
                },
              ],
            });
          }
        }
      });
    },
  });
}
